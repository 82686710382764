import React, { useState } from 'react';
import { RadioGroup, Radio, SelectPicker } from 'rsuite';
import { secondsToString } from "../utils";
import { translate } from "../languages";

export default function SkipTime(props) {
  const { buttons, handleChange, panelValues, icon, placeholderText, defaultSelected } = props;

  const [value, setValue] = useState(null);

  // Convert button values to integers if they are strings
  const parsedButtons = buttons.map(button => parseInt(button, 10));

  const timeOptions = [
    ...buttons.filter(value => Number.isInteger(value)).map(value => ({
      label: value === -1 ? "auto" : secondsToString(value),
      value
    }))
  ];

  const handleTimeChange = (value) => {
    setValue(value);
    if (value !== null) {
      handleChange(value);
    }
  };

  const buttonStyle = {
    display: 'flex',
    verticalAlign: 'middle',
    justifyContent: 'center',
    alignItems: 'center',
  };

  if (parsedButtons.length === 0) { return null };
  return (
    <>
      {/* Horizontal Time Picker for larger screens */}
      <div className="global_buttons global_time time-picker-horizontal">
        <RadioGroup
          name="timeGroup"
          inline
          appearance="picker"
          className="footerBtnGroup row"
          defaultValue={defaultSelected}
          onChange={(value) => { handleChange(value); }}
        >
          <span
            style={{ ...buttonStyle, width: "120px" }}
          >
            <i className={`${icon} me-2`}></i>
            <div>{translate(placeholderText)}</div>
          </span>
          {parsedButtons.filter(value => Number.isInteger(value))
            .map((value, index, array) =>
              <Radio
                className={(panelValues?.timeWindow === value || panelValues === value) ? "col selectedElement" : "col"}
                value={value}
                key={`t${value}`}
                style={{
                  textAlign: "center"
                }}
              >
                {value === -1 ? "auto" : secondsToString(value)}
              </Radio>
            )}
        </RadioGroup>
      </div>

      {/* Vertical Time Picker for smaller screens */}
      <div className="select-picker-wrapper time-picker-vertical">
        <SelectPicker
          data={timeOptions}
          style={{ width: 224 }}
          onChange={handleTimeChange}
          defaultValue={defaultSelected}
          placeholder={translate(placeholderText)}
          searchable={false}
          cleanable={false}
          value={value}
        />
      </div>
    </>
  );
};