import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { useIntl } from 'react-intl';
import axios from "axios";
import { Button, Loader, TagPicker, Cascader, Dropdown, Whisper, Tooltip, IconButton } from "rsuite";
import SearchIcon from '@rsuite/icons/Search';
import { cloneDeep } from "lodash";
import { ChartController } from "./";
import { CuttingTools} from "../grid";
import { Statistics } from "./charts";
import { ExcelExportModal, ManageTagListsModal, FeedbackModal } from "../modals";
import { translate } from "../languages";
import { SkipTime, calculateTimeWindow, calculateExtraTimeAndSkipCount } from "../timebuttons";
import {
  makeAuthUrlWithHeaders,
  logErrorIfDevelopmentMode,
  mergeObjects,
  getTimeparams,
  fetchDataHelper,
  acquireToken,
  getColors,
  data2excel,
  popNotification
} from "../utils";
import {
  UseAuthenticationContext,
  ThemeContext,
  NarrowScreenContext,
  SiteContext,
  LanguageContext
} from "../context";
import { MsalContext } from "@azure/msal-react";
import getTagList from "./getTagList";
import CustomPanelSettingsMenu from "../customPanel/customPanelSettingsMenu";
import { TimeFilterButtons } from "../timebuttons";

export default function TagSearchTool(props) {
  const { 
    settings, 
    queryParams, 
    app, 
    excelExport
  } = props;

  const { useAuthentication }                             = useContext(UseAuthenticationContext);
  const msalContext                                       = useContext(MsalContext);
  const { theme }                                         = useContext(ThemeContext);
  const { isNarrowScreen }                                = useContext(NarrowScreenContext);
  const { site }                                          = useContext(SiteContext);
  const { selectedLanguage }                              = useContext(LanguageContext);
  const intl                                              = useIntl();

  /* tag picker related states 
   * tagList contains all the tags fetched from tag endpoint
   * pickedTags are the tags that are selected in tag picker, once tag picker is closed selectedTags are updated with pickedTags
   * selectedOnTop is used to determine if picked tags are shown on top of the tag picker
   * sortOptions is used to determine how tags are sorted in tag picker, options: id, name, description
   */
  const [tagList, setTagList]                             = useState();
  const [pickedTags, setPickedTags]                       = useState([]);
  const [selectedOnTop, toggleSelectedOnTop]              = useState(false);
  const [sortField, setSortField]                         = useState("id");
  const [sortOrder, setSortOrder]                         = useState("asc");
  const [visibleFields, setVisibleFields]                 = useState(["id", "tagname", "description"]);
  /* query building related states 
   * fetchNewData is used to trigger data fetching when new data should be fetched
   * selectedTags are the tags that are used in data fetching, once tag picker is closed selectedTags are updated with pickedTags
   * resolution is used to determine the resolution of the data that is fetched, -1 means same as automatic
   * sdt and edt are the start and end times of the data that is fetched, default time window is 24h
   * loading is used to show loading spinner when data is fetched
   */
  const [fetchNewData, setFetchNewData]                   = useState(true);
  const [selectedTags, setSelectedTags]                   = useState([]);
  const [resolution, setResolution]                       = useState(-1);
  const [sdt, setSdt]                                     = useState(getTimeparams(86400).sdt);
  const [edt, setEdt]                                     = useState(getTimeparams(86400).edt);
  const [loading, toggleLoading]                          = useState(false);
  /* component time related states
   * defaultTime is used to reset time window to default values when needed
   * skipTimeValues are used to update skipTime component when time window is changed
   */ 
  const [defaultTime, setDefaultTime]                     = useState({sdt: getTimeparams(86400).sdt, edt: getTimeparams(86400).edt, timeWindow: 86400});
  const [skipTimeValues, setSkipTimeValues]               = useState();
  /* chart related states
   * chartType is used to determine which chart type is shown in the chart controller, types: "line", "multiline", "scatter", "histogram"
   * cuttingMode is used to determine if chart selection function should remove selected data options: "off", "yAxis", "xAxis"
   * chartData is series that are shown in the chart
   * movingAvg is shown in multiline charts
   * hiddenTags are the tags that are hidden in the chart when eye icon is clicked
   * resetBtn defines if reset button is shown. Will be shown when zoom is used
   * trendsInCharts is used to determine how many trends are shown in mutiline chart, 1 is default
   */
  const [chartType, setChartType]                         = useState("line");
  const [cuttingMode, setCuttingMode]                     = useState("off");
  const [chartData, setChartData]                         = useState();
  const [movingAvg, setMovingAvg]                         = useState();
  const [hiddenTags, setHiddenTags]                       = useState([]);
  const [resetBtn, setResetBtn]                           = useState(false);
  const [trendsInCharts, setTrends]                       = useState(1);
  /* modals related states 
   * showExportModal is used to toggle export modal visibility
   * usedParams contains the parameters that are used in data fetching, so excel export can be done with the same parameters
   * showManageListsModal is used to toggle manage lists modal visibility
   * manageType is used to determine if selected tags should be saved as a new list or if selected list should be updated
   * showFeedbackModal is used to toggle feedback modal visibility
   */
  const [showExportModal, toggleExportModal]              = useState(false);
  const [usedParams, setUsedParams]                       = useState();
  const [showManageListsModal, toggleManageListsModal]    = useState(false);
  const [manageType, setManageType]                       = useState();
  const [showFeedbackModal, setShowFeedbackModal]         = useState(false);
  /* tag list picker related states 
   * customTagLists contains the saved tag lists that are shown in the tag list picker
   * selectedTagList is the selected tag list that is used to set selectedTags and sdt & edt when list is selected
   */
  const [customTagLists, setCustomTagLists]               = useState();
  const [selectedTagList, setSelectedTagList]             = useState();

  const [isOpen, setIsOpen]                               = useState(false); // Custom panel menu open/close state
  const [timeWindow, setTimeWindow]                       = useState(defaultTime.timeWindow); // Time window buttons

  /* Settings */
  const tagIndexing = settings.filter_indexing || "ID";
  const defaultQueryParams = settings.default_queryparams;
  /* Taglist
   * Frontend: sitenav has endpoint: /boa/api/v1/dev:site/tags
   * Backend: uses api-tags when endpoint is /boa/api/v1/dev:site/tags
   * taglist is fetched from API_CONFIG[dev:site].endPoints.filter.url
  */
  const tagsEndpoint = settings?.endpoints?.tags || `/boa/api/v1/${site}/tags`;
  /* History
   * Frontend: sitenav has endpoint: /boa/api/v1/dev:site/history
   * Backend: uses api-history when endpoint is /boa/api/v1/dev:site/history
   * taglist is fetched from API_CONFIG[dev:site].endPoints.queryTags.url
   * FWD exception 1: if endpoint is /boa/api/v1/dev:site/fwd/something/api/history
   * taglist is fetched from API_CONFIG[dev:site].endPoints.queryTags.url/something/api/history
   * FWD exception 2: if endpoint doesnt end with /history it need to have /fwd/ to work
   * e.g. /boa/api/v1/dev:site/fwd/proxy/something/tagsearch
   * is fetched from API_CONFIG[dev:site].endPoints.queryTags.url/proxy/something/tagsearch
  */
  const tagHistory = settings?.endpoints?.history || `/boa/api/v1/${site}/history`;
  const timeButtons = settings.timeButtons;
  const movingAverage = settings?.endpoints?.simpleMovingAverage;
  const resolutionSelection = settings.resolutions;
  const width = "99.5%";
  /* clonedeep customTaglist so inputpicker won't add extra objects in it */
  const tagListPickerValues = cloneDeep(customTagLists);
  /* ref is used to trigger onClose function */
  const tagPickerRef = useRef();
  const feedbackRef = useRef(); //used to close tooltip on mobile when clicked
  const excelExportRef = useRef(); //used to close tooltip on mobile when clicked
  const quickExportRef = useRef(); //used to close tooltip on mobile when clicked

  // Loads session storage contents to use them as initial values
  useEffect(() => {
    const autosave = sessionStorage.getItem(`${site}-autosave`);
    if (autosave) {
      const {
        sdt,
        edt,
        timeWindow,
        extraTime,
        skipTimeCounter,
        resolution,
        chartType,
      } = JSON.parse(autosave);

      setSkipTimeValues({ sdt: sdt || getTimeparams(86400).sdt, edt: edt || getTimeparams(86400).edt, timeWindow: timeWindow || 86400, extraTime: extraTime || 0, skipTimeCounter: skipTimeCounter || 0 });
      setEdt(edt || getTimeparams(86400).edt);
      setSdt(sdt || getTimeparams(86400).sdt);
      setResolution(resolution || -1);
      setChartType(chartType || "line");
      setTimeWindow(timeWindow || 86400);
    }
  }, [site]);

  /* Fetches tag list */
  useEffect(() => {
    // Update selected tag list when page is reloaded
    let autosaveData = JSON.parse(sessionStorage.getItem(`${site}-autosave`));
    let tagListName = autosaveData?.tagListName || "";
    setSelectedTagList(tagListName);
  
    getTagList(useAuthentication, msalContext, selectedLanguage, tagsEndpoint)
      .then(tagList => {
        setTagList(tagList.map(listItem => {
          return Object.fromEntries(
            Object.entries(listItem).map(([k, v]) => [k.toLowerCase(), v])
          );
        }));
      })
      .then(() => {
        if (sessionStorage.getItem(`${site}-autosave`) && (JSON.parse(sessionStorage.getItem(`${site}-autosave`))).tags !== null) {
          let storageObject = JSON.parse(sessionStorage.getItem(`${site}-autosave`));
          if (storageObject.site === site && storageObject.app === app.active) {
            setSelectedTags(storageObject.tags);
            setPickedTags(storageObject.tags);
          } else {
            setSelectedTags([]);
            setPickedTags([]);
          }
        }
      })
      .catch(e => {
        logErrorIfDevelopmentMode(e);
      });
  }, [useAuthentication, msalContext, tagsEndpoint, site, app, selectedLanguage]);
/* Fetch saved custom tag lists */
  useEffect(()=> {
    let mounted = true;
    async function getCustomLists() {
      let serverLists = [];
      if (useAuthentication && msalContext.accounts.length > 0) { /* Server lists */
        let url = `/boa/api/v1/${site}/${msalContext.accounts[0].username}/taglists`;
        let headers = {};
        if (msalContext.accounts.length > 0) { [url, headers] = await makeAuthUrlWithHeaders(url, headers, acquireToken(msalContext)); }
        try {
          await axios.get(url, { headers }).then(response => {
            if (response.data && response.data.length > 0) { serverLists = response.data }
          });
        } catch (e) {
          logErrorIfDevelopmentMode(e);
        }
      }
      /* Local storage lists */
      let savedTagLists = site + "_savedTagLists";
      let customTagLists = [];
      if (localStorage.getItem(savedTagLists)) {
        customTagLists = JSON.parse(localStorage.getItem(savedTagLists));
        /* Add old local storage lists in a new folder 
        first we check that local_storage_lists is not existsing yet */
        if (!customTagLists.some(list => list.value === "local_storage_lists") && customTagLists.length > 0) {
          let filteredLists = customTagLists.filter(list => list.value !== "local_storage_lists");
          /* Each list has a valueKey that is needed when list is selected and tags etc. changes */
          let listWithValueKeys = filteredLists.map(list =>  ({...list, valueKey: `local_storage_lists?Saved?${list.value}`}));
          customTagLists.push({ 
            "label": msalContext.accounts.length > 0 ? "Local storage" : "Lists", 
            "value": "local_storage_lists", 
            "valueKey": "local_storage_lists", 
            "children": [{ "label": "Saved", "value": "Saved", "children": listWithValueKeys }] 
          })
        }
      }
      let mergedLists = [...customTagLists, ...serverLists];
       /* filter is needed so those old lists arent included in folder structure. They are included in local_storage_lists list */
      if (mounted) { setCustomTagLists(  mergedLists.filter(lists => lists.children)); }
    }
    getCustomLists();
    return () => { mounted = false; };
  },[msalContext, site, selectedTagList, useAuthentication]);

  /* Update selected tags when tag search is opened by clicking tag name in reports/overview */
  useEffect(()=> {
    if (queryParams && tagList) {
      let queryTags = [];
      if (settings.tags) { //use tags defined in showdata settings
        if (tagIndexing === "TagName"){ queryTags = tagList.filter(tag => settings.tags.includes(tag.tagname)).map(tag => tag.id); }
        else { queryTags = tagList.filter(tag => settings.tags.includes(tag.id)).map(tag => tag.id);}
      } else { // use tags in queryParams if no tags are defined
        let queryParamsTags = [];
        for (let params of queryParams) {
          if (tagIndexing === "TagName") {
            let queryTags = tagList.filter(tag => params.tags.includes(tag.tagname)).map(tag => tag.id);
            queryTags.forEach(tag => queryParamsTags.push(tag)) // add all tags in the same array
          } else {
            let queryTags = tagList.filter(tag => params.tags.includes(tag.id)).map(tag => tag.id);
            queryTags.forEach(tag => queryParamsTags.push(tag)) // add all tags in the same array
          }
        }
      }
      setSelectedTags(queryTags);
      setPickedTags(queryTags);
      if (timeButtons) {
        const value = timeButtons.find(btn => typeof(btn) === "string");
        setSdt(getTimeparams(parseInt(value)).sdt);
        setEdt(getTimeparams(parseInt(value)).edt);
      }
    }
  },[queryParams, tagList, tagIndexing, timeButtons, settings]);

  /* Fetch data when selected tags or sdt/edt changes */
  useEffect(()=> {
    let mounted = true;
    async function getChartData(endpoint, fetchAvg = false) {
      if (selectedTags.length > 0 && sdt && edt) { // sdt and edt are defined in componentDidMount
        if (sdt !== edt) { // don't fetch data if start time is the same than end time
          let tags = selectedTags.join(); //tag IDs
          if (tagIndexing === "TagName") { //data fetching requires TagNames instead of IDs
            tags = tagList
            .filter(tag => selectedTags.includes(tag.id)) //filter taglist to show only selected tags
            .sort((a,b) => selectedTags.indexOf(a.id) - selectedTags.indexOf(b.id)) //sort taglist to show tags in selection order
            .map(tag => tag.tagname) //show only TagNames
            .join(); //create string that contains all TagNames
          }
          const params = mergeObjects( 
            {sdt: sdt, edt: edt, tags: tags, resolution: resolution}, 
            defaultQueryParams
          );
          toggleLoading(true);
          
          let fetchedData;
          if (useAuthentication) {
            fetchedData = msalContext.accounts.length > 0 && await fetchDataHelper({
              url: endpoint,
              params,
              useAuthentication: useAuthentication,
              authContext: msalContext
            })
          } else {
            fetchedData = await fetchDataHelper({
              url: endpoint,
              params
            })
          }
    
          if (mounted && fetchedData) { 
            const colors = getColors(theme);
            if (fetchAvg) {
              setMovingAvg(fetchedData.map(data => { 
                return {
                  ...data,
                  ...tagList.find(tag => tag.tagname === data.name)
                }
              }));           
            } else {
              setChartData(fetchedData.map((data, index) => { 
                let colorIndex = index;
                if (index > colors.length - 1 ){ colorIndex = index % colors.length }
                return {
                  ...data,
                  ...tagList.find(tag => tag.tagname === data.name),
                  ...{color: colors[colorIndex]}
                }
              }));
            }        
            setUsedParams(params);
            toggleLoading(false);
          }
        }
      } else { // clear data when there are no tags selected
        if (mounted) { 
          setChartData();
          setUsedParams();
          setMovingAvg();
        }
      }
    }
    
    if (fetchNewData) { // tags are added/removed
      getChartData(tagHistory);
      if (movingAverage){ getChartData(movingAverage, "avg"); }
    } else { // only tag order is changed
      setChartData(cData => cData.map((data, index) => { return {...data, ...tagList.find(tag => tag.id ===  selectedTags[index] )}} ));
    }
    return () => { mounted = false; };
  },[sdt, edt, selectedTags, fetchNewData, tagList, tagIndexing, defaultQueryParams, msalContext, useAuthentication, tagHistory, movingAverage, theme, resolution])
  
  /* add correct visibility and color for each series */
  const handleChartData = () => {
    let visibleCharts = null;
    const colors = getColors(theme);
    if (Array.isArray(chartData)) {
      visibleCharts = chartData.map((data, index) => {
        const hidden = hiddenTags.some(tag => tag === data.id);
        data = Object.assign(data, {visible: !hidden});
        if (!data.color) { data = Object.assign(data, {color: colors[index]}); }  
        if (data.data && data.data.every(data => data[1] === 1 || data[1] === 0 )) { data = Object.assign({step: "left" }, data); }
        return data;
      })
    } else {
      visibleCharts = chartData;
    }
    return visibleCharts;
  }

  const populateStorage = (value, tagListName = null) => {
    if (app && app.active) {
      const storageObject = {"site": site, "app": app.active, "tags": value};
      if (tagListName) {
        storageObject.tagListName = tagListName;
      }
      const autosaveData = JSON.parse(sessionStorage.getItem(`${site}-autosave`));

      if (autosaveData && autosaveData.site === site && autosaveData.app === app.active) {
        if (tagListName) {
          autosaveData.tagListName = tagListName;
        }
        autosaveData.tags = value;
        sessionStorage.setItem(`${site}-autosave`, JSON.stringify(autosaveData));
      } else {
        const storageObject = {"site": site, "app": app.active, "tags": value};
        if (tagListName) {
          storageObject.tagListName = tagListName;
        }
        sessionStorage.setItem(`${site}-autosave`, JSON.stringify(storageObject));
      }
    }
  }

  /* Function is run after tag picker is used or when tag list picker is used */
  const handleTagPickerChange = (values, options={sdt: null, edt: null }) => {
    // make default state object with new tags and current timestamp  
    const newStateObject = {
      selectedTags: values,
      time: defaultTime.timeWindow,
      sdt: new Date(sdt),
      edt: new Date(edt)
    };

    if (options.sdt && options.edt) {
      const newTimeWindow = calculateTimeWindow(options.sdt, options.edt, false);
      newStateObject.time = newTimeWindow / 1000;
      newStateObject.sdt = options.sdt;
      newStateObject.edt = options.edt;
    }
  
    /*  selectedTags only when values (pickedTags) have changed 
    *   otherwise new data is fetched every time tagpicker is closed.
    *   SelectedTags should also be updated when selected custom tag list changes
    */
    if (selectedTags !== values && values) {
      setSelectedTags(newStateObject.selectedTags);
      handleTimeChange(newStateObject);
    }
  }

  const selectSavedTags = (value) => {  // value = user_email?folder_name?tag_list_name
    const selectedUser = value.split("?")[0]; // user_emails
    const selectedFolder = value.split("?")[1]; // folder_name
    const selectedList = value.split("?")[2]; // tag_list_name
    const userLists = customTagLists.find(list => list.value === selectedUser);
    const savedTags = userLists.children.find(folder => folder.value === selectedFolder).children.find(v => v.value === selectedList);
    const options = {sdt: savedTags.sdt, edt: savedTags.edt};
    setPickedTags(savedTags.tags);
    setSelectedTagList(value);
    handleTagPickerChange(savedTags.tags, options);
    return savedTags.tags;
  }

  const passDate = useCallback(({sdt, edt, timeWindow, extraTime, skipTimeCounter}) => {
    setSdt(sdt);
    setEdt(edt);
    setSkipTimeValues({ sdt, edt, timeWindow, extraTime, skipTimeCounter });

    // Save arguments to session storage
    const autosave = JSON.parse(sessionStorage.getItem(`${site}-autosave`)) || {};
    autosave.sdt = sdt;
    autosave.edt = edt;
    autosave.timeWindow = timeWindow;
    autosave.extraTime = extraTime;
    autosave.skipTimeCounter = skipTimeCounter;
    sessionStorage.setItem(`${site}-autosave`, JSON.stringify(autosave));

  },[site]);


  const handleTimeChange = (newTime) => {
    let newSdt = new Date(newTime.sdt);
    newSdt.setSeconds(0,0);
    let newEdt = new Date(newTime.edt);
    newEdt.setSeconds(0,0);
    const [skipCount, extraTime] = calculateExtraTimeAndSkipCount(newTime.time * 1000, newEdt.getTime());
    if (newTime) { 
      setSdt(newSdt.toISOString());
      setEdt(newEdt.toISOString());
      setSkipTimeValues({ sdt: newSdt.toISOString(), edt: newEdt.toISOString(), timeWindow: newTime.time, extraTime: extraTime, skipTimeCounter: skipCount });
      setFetchNewData(true);
      setResetBtn(false);
    }

    // Save start and end date to session storage to keep the same 
    // time window when tag picker is opened again
    let autosaveData = sessionStorage.getItem(`${site}-autosave`) ? JSON.parse(sessionStorage.getItem(`${site}-autosave`)) : {};
    autosaveData.sdt = newSdt.toISOString();
    autosaveData.edt = newEdt.toISOString();
    sessionStorage.setItem(`${site}-autosave`, JSON.stringify(autosaveData));
  }

  const zoomRange = (values = {}, zoomUsed = false) => {
    setFetchNewData(true);
    if (zoomUsed) {
      if (!defaultTime.zoom) { // only when zoom is used the first time
        const originalTimeWindow = calculateTimeWindow(sdt, edt);
        const [originalSkipCount, originalExtraTime ] = calculateExtraTimeAndSkipCount(originalTimeWindow, edt);
        setDefaultTime({ 
          sdt: sdt, 
          edt: edt, 
          timeWindow: originalTimeWindow / 1000, 
          extraTime: originalExtraTime,
          skipTimeCounter: originalSkipCount,
          zoom: true
        }) 
      }
      const newSdt = new Date(values.sdt);
      const newEdt = new Date(values.edt);
      const newTimeWindow = calculateTimeWindow(newSdt, newEdt);
      const [newSkipTimeCount, newExtraTime ] = calculateExtraTimeAndSkipCount(newTimeWindow, values.edt);
      setSdt(newSdt.toISOString());
      setEdt(newEdt.toISOString());
      setResetBtn(true);
      setSkipTimeValues({ sdt: newSdt.toISOString(), edt: newEdt.toISOString(), timeWindow: newTimeWindow / 1000, extraTime: newExtraTime, skipTimeCounter: newSkipTimeCount })
    } else {
      setSdt(new Date(defaultTime.sdt).toISOString());
      setEdt(new Date(defaultTime.edt).toISOString());
      setResetBtn(false);
      setDefaultTime({ value: { sdt: defaultTime.sdt, edt: defaultTime.edt }, time: defaultTime.timeWindow });
      setSkipTimeValues({ sdt: new Date(defaultTime.sdt).toISOString(), edt: new Date(defaultTime.edt).toISOString(), timeWindow: defaultTime.timeWindow, extraTime: defaultTime.extraTime || 0, skipTimeCounter: defaultTime.skipTimeCounter || 0 })
    }
  };

  const toggleVisibility = (ID) => {
    let array = hiddenTags;
    if (array.find(id => id === ID)) {
      const removeIndex = array.findIndex(id => id === ID);
      array.splice(removeIndex, 1);
    } else if (typeof(ID) === "string" ) { // header icon, show/hide all
      if (ID === "fa-eye") { //hide all
        array = cloneDeep(selectedTags);
      } else { //show all
        array = [];
      }
    } else { // row icon, show/hide tag
      array.push(ID)
    }

    let chartDataVisible = cloneDeep(chartData);
    if (typeof ID === "number") { // show/hide single series
      if (typeof chartDataVisible.find(data => data.id === ID).visible === "boolean") { // toggle visible value if exists
        let i = chartDataVisible.findIndex(data => data.id === ID);
        chartDataVisible[i].visible = !chartDataVisible[i].visible;
      } else { // add visible false
        let i = chartDataVisible.findIndex(data => data.id === ID);
        chartDataVisible[i] = Object.assign({visible: false}, chartDataVisible[i]);
      }
    } else { // show/hide every series
      let nextValue = chartDataVisible.some(data => data.visible === false);
      for (let i = 0; i < chartDataVisible.length; i++) {
        if (typeof chartDataVisible[i].visible === "boolean") { // toggle visible values if exists
          chartDataVisible[i].visible = nextValue;
        } else { // add visible values
          chartDataVisible[i] = Object.assign({visible: nextValue}, chartDataVisible[i])
        }
      }
    }
    setChartData(chartDataVisible);
    setHiddenTags(array);
  }


  /* Saving options, either save button or a dropdown menu */
  const getSavingOptions = () => {
    return (
      <div className="col-12 col-md-6 col-lg-3 col-xxl-2">
        {selectedTagList ? getDropdownOptions() : getSaveButton()}
      </div>
    );
  }
  
  const getSaveButton = () => {
    return (
      selectedTags.length > 0 && (
        <Button
          color="green"
          style={{ width:"100%", height:"44.6px", left:"-10px" }}
          onClick={() => {
            setManageType("Add");
            toggleManageListsModal(true);
          }}
        >
          <i className="fas fa-save me-2"/>
          {translate("Save as a new tag list")}
        </Button>
      )
    );
  }

  const getDropdownOptions = () => {
    return(
      <Dropdown title={translate("Options")} classPrefix="dropdown tagListOptions" style={{ width: "100%", height: "43px"}}>
        <DropdownItem type = "Update"   icon = "fa-sync"        text = "Update tag list" />
        <DropdownItem type = "Add"      icon = "fa-save"        text = "Save a new tag list" />
      </Dropdown>
    );
  }

  const DropdownItem = ({type, icon, text}) => {
    return (
      <Dropdown.Item onClick={() => { setManageType(type); toggleManageListsModal(true); }}>
        <i className={`fas ${icon} me-2`}/> {translate(text)}
      </Dropdown.Item>
    );
  }

  /* Modal for saving/updating tag lists */
  const manageTagList = () => {
    return (
      <ManageTagListsModal
        show={showManageListsModal}
        selectedTags={selectedTags}
        customTagLists={customTagLists}
        selectedTagList={selectedTagList}
        manageType={manageType}
        user={msalContext.accounts.length > 0 ? msalContext.accounts[0].username : "local_storage_lists"}
        site={site}
        tagListPickerValues={tagListPickerValues}
        onHide={(newList)=> {
          toggleManageListsModal(false);
          setSelectedTagList(newList);
        }}
        sdt={sdt}
        edt={edt}
      />
    );
  }

  const renderAnalyzeHeader = () => {
    const buttonStyle = {  
      padding: '8px 10px 8px 10px',
      display: 'inline-block',
      verticalAlign: 'middle',
      borderRight:"1px solid var(--rs-border-primary)",
      borderBottom: "none",
      textAlign: "center",
      whiteSpace: "nowrap"
    };

    const defaultClass = "col-12 col-lg-6 px-2";

    const timeRow = () => {
      const defaultSelected = skipTimeValues ? parseInt(skipTimeValues.timeWindow) : 86400;
      const buttons = settings.timeButtons ? settings.timeButtons : [600, 1800, 3600, 28800, 86400, 604800];
      const timeWindow = JSON.parse(sessionStorage.getItem(`${site}-autosave`))?.timeWindow || 86400;

      return (
        <div className="row">
          <div className={defaultClass}>
            <TimeFilterButtons
              buttons={buttons} 
              panelValues={timeWindow} 
              icon="fas fa-clock" 
              placeholderText="Time"
              defaultSelected={defaultSelected}
              handleChange={ (value) => {
                let autosave = sessionStorage.getItem(`${site}-autosave`);
                let autosaveObj = autosave ? JSON.parse(autosave) : {};
                autosaveObj.timeWindow = value;
                sessionStorage.setItem(`${site}-autosave`, JSON.stringify(autosaveObj));
              
                const dates = getTimeparams(value);
                setTimeWindow(value);
                handleTimeChange({
                  sdt: new Date(dates.sdt), 
                  edt: new Date(dates.edt), 
                  time: value
                })
              }}
            />
            </div>

        <div className={defaultClass}>
          <SkipTime
            passDate={passDate} 
            defaultValue={defaultTime.timeWindow}
            panelTimeValues={skipTimeValues}
            smallPanel={isNarrowScreen}
          />
        </div>
      </div>
    )}

    const resCutRow = () => {
      return(
        <div className="row">
        {resolutionSelection &&
          <div className={defaultClass}>
            <TimeFilterButtons
              buttons={resolutionSelection}
              panelValues={resolution}
              icon="fas fa-wave-square"
              placeholderText="Resolution"
              defaultSelected={resolution}
              handleChange={ (value) => {
                let autosave = sessionStorage.getItem(`${site}-autosave`);
                let autosaveObj = autosave ? JSON.parse(autosave) : {};
                autosaveObj.resolution = value;
                sessionStorage.setItem(`${site}-autosave`, JSON.stringify(autosaveObj));
                setResolution(value);
              }}
            />
          </div>
        }

        {!isNarrowScreen && chartType === "line" &&
          <div className={defaultClass}>
            <CuttingTools 
              onClick={ (mode) => setCuttingMode(mode) } 
              style={buttonStyle} 
              selectedMode={cuttingMode}
              titleClass="col-12 col-md-3"
            />
          </div>
        }

        </div>
      );
    }

    const showResCutRow = () => {
      if (resolutionSelection) { return true; }
      else if (!isNarrowScreen && chartType === "line") { return true; }
      else { return false; }
    } 

    return(
      <div className="border-bottom pb-3">
        {timeRow()}
        {showResCutRow() && resCutRow()}    
      </div>
    );
  }

  const getAnalysisButtons = () => {
    return (
      <div className="row mt-3 pb-3 border-bottom">
        <AnalyzeButton chartType = "line" text = "Timeserie" />
        {chartData?.length > 1 && <AnalyzeButton chartType = "multiline" text = "Multiline" />}
        {chartData?.length > 1 && settings.endpoints?.scatter && <AnalyzeButton chartType = "scatter" text = "Scatter" />}
        {/* Area chart not working -> Highcharts error #18 */}
        {/* <AnalyzeButton chartType = "area" text = "Area" /> */}
        <AnalyzeButton chartType = "histogram" text = "Histogram" />
        <div className="col d-flex justify-content-end" style={{ gap: 10 }}>
          {/* Feedback */}
          {useAuthentication && (
            <Whisper
              ref={feedbackRef}
              placement="left"
              trigger="hover"
              speaker={<Tooltip>{translate("Give Feedback")}</Tooltip>}
            >
              <Button
                appearance="default"
                className="tag-search-button"
                onClick={() => { setShowFeedbackModal(true); feedbackRef.current.close(); }}
              >
                <i className="fas fa-envelope" />
              </Button>
            </Whisper>
          )}

          {/* Excel export */}
          {excelExport && usedParams && (
            <Whisper
              ref={excelExportRef}
              placement="left"
              trigger="hover"
              speaker={<Tooltip>{translate("Excel export")}</Tooltip>}
            >
              <Button
                appearance="default"
                className="tag-search-button"
                onClick={() => { toggleExportModal(true); excelExportRef.current.close(); }}
              >
                <i className="fas fa-file-excel" />
              </Button>
            </Whisper>
          )}

          {/* Quick excel */}
          {settings?.quickExport && (
            <Whisper
              ref={quickExportRef}
              placement="left"
              trigger="hover"
              speaker={<Tooltip>{translate("Quick Excel")}</Tooltip>}
            >
              <Button
                appearance="default"
                className="tag-search-button"
                onClick={() => {
                  data2excel(chartData, {
                    onError: () => popNotification({
                      type: "error",
                      text: "Something went wrong"
                    })
                  });
                  quickExportRef.current.close();
                }}
              >
                <i className="fas fa-file-download" />
              </Button>
            </Whisper>
          )}

          {/* Custom panel button */}
          <Whisper
            ref={quickExportRef}
            placement="left"
            trigger="hover"
            speaker={<Tooltip>{translate("Create a custom panel from current tags")}</Tooltip>}
          >
            <Button
              appearance="default"
              className="tag-search-button"
              onClick={() => setIsOpen(true)}
            >
              <i className="fas fa-edit" />
            </Button>
          </Whisper>
        </div>


          {resetBtn && 
          <div style={{position:"relative"}}>
            <div
            className="btn btn-inline analysis-tabs btn-custom-reset tab-btn"
            style={{position: "absolute", right: "0px", top: "35px", zIndex: 10}}
            onClick={() => zoomRange()}
          >
            {translate("Reset Zoom")}
          </div>
        </div>
        }
      </div>
    );
  }

  const AnalyzeButton = ({chartType, text}) => {
    return(
      <div
        className={addClassName(chartType)}
        onClick={ () => {
          let autosave = sessionStorage.getItem(`${site}-autosave`);
          let autosaveObj = autosave ? JSON.parse(autosave) : {};
          autosaveObj.chartType = chartType;
          sessionStorage.setItem(`${site}-autosave`, JSON.stringify(autosaveObj));
          setChartType(chartType)
        }}
      >
        {translate(text)}
      </div>
    );
  }
  
  const addClassName = (value) => {
    let classes = "col-12 col-md btn btn-inline btn-custom-secondary analysis-tabs tab-btn";
    if (value === chartType) {
      classes += " selected";
    }
    return classes;
  }

  const updateOrder = (data) => {
    setFetchNewData(false);
    setSelectedTags(data.map(tag => tag.id));
    setPickedTags(data.map(tag => tag.id));
    setChartData(data)
  }

  const renderAnalyzeContent = () => {
    return(
      <div>
        <ChartController
          chartData={handleChartData()}
          chartType={chartType}
          movingAverageData={movingAvg}
          tagsInfo={tagList.filter(tag => selectedTags.includes(tag.id))}
          timeValue={86400}
          settings={settings}
          cuttingMode={cuttingMode}
          cuttingRange={setChartData}
          handleZoom={zoomRange}
          loading={loading}
          trendsInCharts={trendsInCharts}
          setTrends={setTrends}
          hiddenTags={hiddenTags}
          selectedTags={selectedTags}
        />

        <Statistics
          chartData={handleChartData()}
          tagList={tagList}
          tags={selectedTags}
          timeValue={ {sdt, edt} }
          settings={settings}
          toggleVisibility={toggleVisibility}
          updateOrder={updateOrder}
        />

        <ExcelExportModal
          show={showExportModal}
          params={usedParams}
          {...props}
          onHide={()=> toggleExportModal(false)}
        />
      </div>
    );
  }

  const renderMessage = () => {
    if (selectedTags?.length > 0) { //loading message when chartData is loaded
      return(
        <div>

          <div style={{ display:"inline-block" }}>
            <Loader size="sm" />
          </div>

          <div className="ms-3" style={{display:"inline-block"}}>
            {translate("object_loading")}
          </div>

        </div>
      );
    } else { //message when no tags are selected
      return(<div>{translate("Select a tag to continue")}</div>);
    }
  }

  const tagListPicker = () => {
    let defaultValue;
    if (selectedTagList) { defaultValue = selectedTagList }
    else if (Array.isArray(tagListPickerValues) && tagListPickerValues.length > 0) { defaultValue = intl.formatMessage({ id: "Select" }) }
    else { defaultValue = intl.formatMessage({ id: "No Tag Lists" }) }
    return(
      <div className="col-12 col-md-6 col-lg-9 col-xxl-5">
        <Cascader 
          key={selectedTagList + "tagListPicker"}
          classPrefix="taglist rs-picker"
          data={tagListPickerValues}
          cleanable={false}
          block={true}
          size="lg"
          defaultValue={defaultValue}
          valueKey="valueKey"
          onChange={(value, event) => {
            const listedTags = selectSavedTags(value, event);
            populateStorage(listedTags, value);
          }}
          placeholder={defaultValue}
        />
      </div>
    );
  }

  /* Tag picker and its functions */
  const tagPicker = () => {
    return(
      <div className="col-12 col-lg-12 col-xxl-5 tagPicker">
        <TagPicker
          virtualized={true}
          key={selectedTagList + "_tagPicker_" + selectedTags.join()}
          ref={tagPickerRef}
          data = {selectedOnTop ?
            [
            ...tagList.filter(tag => pickedTags.includes(tag.id)).sort((a,b) => sortTags(a,b)),
            ...tagList.filter(tag => !pickedTags.includes(tag.id)).sort((a,b) => sortTags(a,b))
            ] : [...tagList.sort((a,b) => sortTags(a,b))]
          }
          style = {{ width: "98%" }}
          size = "lg"
          menuStyle = {{ zIndex: 100, width: "100%", maxWidth: "400px", maxHeight: window.innerHeight * 0.6 }}
          valueKey = "id"
          labelKey = "id"
          cleanable
          defaultValue={selectedTags}
          placeholder={pickedTags.length > 0 ? 0 : translate("Search tags")}
          searchBy={(keyword,label,item) =>{if (Object.values(item).filter(removeNulls => removeNulls).some(value => value.toString().toLowerCase().includes(keyword.toLowerCase()))){return item}}}
          preventOverflow={true}
          renderMenuItem={(label, item) => customTagPickerMenu(item)}
          renderExtraFooter={() => <TagPickerExtraFooter />}
          onChange={ (value, event) => { 
            if (Array.from(event.currentTarget.classList)[0] === "rs-tag-icon-close") { // individual tag is removed by clicking x icon
              setPickedTags(value);
              handleTagPickerChange(value);
              populateStorage(value);
            } else { setPickedTags(value); populateStorage(value); }
          }}
          onClose={ () => handleTagPickerChange(pickedTags) }
          onClean={ () => { handleTagPickerChange([]); setHiddenTags([]); }}
        />
      </div>
    );
  }

  const sortTags = (a, b) => {
    if (sortField === "name") { 
      if (sortOrder === "asc") { return a.tagname && b.tagname ? a.tagname.toLowerCase().localeCompare(b.tagname.toLowerCase()) : -1; } 
      else { return a.tagname && b.tagname ? b.tagname.toLowerCase().localeCompare(a.tagname.toLowerCase()) : -1; }
    } else if (sortField === "description") { 
      if (sortOrder === "asc") { return a.description && b.description ? a.description.toLowerCase().localeCompare(b.description.toLowerCase()) : -1; }
      else { return a.description && b.description ? b.description.toLowerCase().localeCompare(a.description.toLowerCase()) : -1; }
    } else { 
      if (sortOrder === "asc") { return a.id > b.id ? 1 : -1; } 
      else { return a.id > b.id ? -1 : 1; }
    }
  }

  const customTagPickerMenu = (item) => {
    let descriptionLength = 12;
    let tagnameLength = 12;
    let idLength = 1;
    if (visibleFields.length === 3) {
      descriptionLength = 6;
      tagnameLength = 5; // id col is always 1
    } else if (visibleFields.includes('tagname') && visibleFields.includes('description')){ // id is hidden
      descriptionLength = 6;
      tagnameLength = 6;
    } else if (visibleFields.length === 1) { // only 1 field is visible
      idLength = 12;  
    } else { // id and either tagname or description is visible
      tagnameLength = 11; // id col is always 1
      descriptionLength = 11; // id col is always 1
    }
    return (
      <div className="row" style={{ display: "flex", alignItems: "center" }}>
        
        {visibleFields.includes('id') &&
          <div 
            className={`col-${idLength}`} 
            style={{ 
              overflow: "hidden", 
              textOverflow: "ellipsis", 
              whiteSpace: "nowrap", 
              fontSize: 14, 
              flexShrink: 0 
            }}
          >
            <span>{item.id}</span> 
          </div>
        }

        {visibleFields.includes('tagname') &&
          <div 
            className={`col-${tagnameLength}`} 
            style={{ 
              overflow: "hidden", 
              textOverflow: "ellipsis", 
              whiteSpace: "nowrap", 
              fontSize: 14, 
              flexShrink: 0 
            }}>
              <span>{item.tagname}</span>
          </div>
        }

        {visibleFields.includes('description') && 
          <div 
            className={`col-${descriptionLength}`} 
            style={{ 
              overflow: "hidden", 
              textOverflow: "ellipsis", 
              whiteSpace: "nowrap", 
              fontSize: 14, 
              flexGrow: 1 
            }}>
            <span>{item.description}</span>
          </div>
        }

      </div>
    );
  };

const handleSort = (field) => {
  if (sortField === field) {
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  } else {
    setSortField(field);
    setSortOrder('asc');
  }
};

const toggleFieldVisibility = (field) => {
  setVisibleFields((prevFields) =>
    prevFields.includes(field)
      ? prevFields.filter((f) => f !== field)
      : [...prevFields, field]
  );
};

const getSortIcon = (field) => {
  if (sortField === field) {
    return sortOrder === 'asc' ? 'fas fa-sort-amount-down-alt' : 'fas fa-sort-amount-up-alt';
  }
  return 'fas fa-sort gray';
};
  const TagPickerExtraFooter = () => {
    const iconStyle = { fontSize: 16, marginRight: 10, cursor: "pointer" };
    return(
      <div className="tagPickerExtraFooter">
        
        <div 
          style={{ 
            display: "flex", 
            justifyContent: "space-between", 
            alignItems: "center", 
            padding: "0px 15px",
            marginBottom: "15px" 
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
            <i 
              className={visibleFields.includes("id") ? "fas fa-eye" : "fas fa-eye-slash gray"} 
              style={iconStyle} 
              onClick={() => toggleFieldVisibility('id')} 
            />
            <i 
              className={getSortIcon('id')} 
              style={iconStyle} 
              onClick={() => handleSort('id')} 
            />
            <span>{translate("ID")}</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', margin: 'auto' }}>
            <i 
              className={visibleFields.includes("tagname") ? "fas fa-eye" : "fas fa-eye-slash gray"} 
              style={iconStyle} 
              onClick={() => toggleFieldVisibility('tagname')} 
            />
            <i 
              className={getSortIcon("name")} 
              style={iconStyle} 
              onClick={() => handleSort('name')} 
            />
            <span>{translate("Tagname")}</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}>
            <i 
              className={visibleFields.includes("description") ? "fas fa-eye" : "fas fa-eye-slash gray"} 
              style={iconStyle} 
              onClick={() => toggleFieldVisibility('description')}
            />
            <i 
              className={getSortIcon("description")} 
              style={iconStyle} 
              onClick={() => handleSort('description')} 
            />
            <span>{translate("Description")}</span>
          </div>
        </div>

        <div 
          style={{ 
            display: "flex", 
            justifyContent: "space-between", 
            alignItems: "center", 
            padding: "0px 15px"
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <i 
              className={selectedOnTop ? "fas fa-check-square" : "far fa-square" }
              style={iconStyle} 
              onClick={() => toggleSelectedOnTop(!selectedOnTop)} 
            />
              {translate("Selected on the top")}
          </div>
          <div style={{ display: 'flex', alignItems: 'center'}}>
            <IconButton 
              className="search_btn" 
              appearance="ghost" 
              icon={<SearchIcon />} 
              placement="left" 
              onClick={()=> tagPickerRef.current.close()}
            >
              {translate("Search")}
            </IconButton>
          </div>
        </div>
      </div>
    );
  }

  /* Render component */
  return(
    <div id="tagsearch" className={"tagsearch card item"} style={{ width: width }}>
      <div className="card-title" style={{padding: "12px 0 30px 12px"}}>
        {tagList ? (
          <div className="row">
            {tagPicker()}
            {tagListPicker()}
            {getSavingOptions()}
            {manageTagList()}
          </div>
        ) : (
          translate("Loading tags")
        )}
      </div>
      <div className="card-body pt-0" style={{ height: "95%" }}>
        {chartData ? (
          <>
            {renderAnalyzeHeader()}
            {getAnalysisButtons()}
            {renderAnalyzeContent()}
          </> 
        ) : ( 
          renderMessage()
        )}
      </div>
      <FeedbackModal
        show={showFeedbackModal}
        title="Tag Search"
        panel="Tag Search"
        site={site}
        onHide={ () => setShowFeedbackModal(false) }
      />
      {isOpen && (
        <CustomPanelSettingsMenu
          settings={[settings]} // use sitenav tagSearch object
          isOpen={isOpen}
          setIsOpen={() => setIsOpen(false)}
          extraSettings={{ 
            tagIds: pickedTags, 
            resolution, 
            timeWindow: timeWindow, 
            chartType: chartType 
          }}
          activeMenu="custom_panels"
        />
      )}
    </div>
  );
}
