import React from 'react';
import { Input, SelectPicker } from 'rsuite';
import { useTranslateToString } from "../languages";

import ToggleVisibilityButton from './toggleVisibilityButton';
import ChartList from './chartList';
import TagList from './tagList';
import TagCustomizer from './tagCustomizer';

const SharedOptions = (props) => {
    const { 
        endpoints, 
        defaultValues, 
        screenSize, 
        onChange,
        setList,
        tagList,
        listId
    } = props;
    
    const handleChange = (key, value) => {
        onChange({ ...defaultValues, [key]: value });
    };

    const placeholderChartName = useTranslateToString('Chart name');
    
    if (screenSize > 585) {
      return (
        <>
          <div className="flex-container">
            <div className={"flex-item"}>
              <Input
                className="input"
                placeholder={placeholderChartName}
                defaultValue={defaultValues?.name}
                onChange={(value) => handleChange("name", value)}
                style={{ minWidth: 150, flex: 1 }}
              />
              <ChartList
                className="chart-list"
                defaultValue={defaultValues?.type}
                onChange={(value) => handleChange("type", value)}
                style={{ minWidth: 150, flex: 1 }}
              />
              <ToggleVisibilityButton
                className="toggle-visibility-button"
                defaultValue={defaultValues?.isPublic}
                onClick={(value) => handleChange("isPublic", value)}
                style={{ width: 130 }}
              />
            </div>
          </div>
    
          {endpoints.length > 1 && (
            <div className="flex-container">
              <div className={"flex-item"}>
                <SelectPicker
                  label="Selected Tag List"
                  defaultValue={endpoints[listId].tags}
                  data={endpoints.map((endpoint) => ({
                    label: endpoint.tags,
                    value: endpoint.tags,
                  }))}
                  onChange={(value) => {
                    setList(
                      endpoints.findIndex((endpoint) => endpoint.tags === value)
                    );
                    handleChange("tags", []);
                  }}
                  style={{ minWidth: 300, flex: 1 }}
                />
              </div>
            </div>
          )}
    
          <div className="flex-container">
            <div className={"flex-item"}>
              <TagList
                className="tag-list"
                defaultValue={defaultValues?.tags}
                endpoints={endpoints[listId]}
                tags={tagList}
                onChange={(value) => handleChange("tags", value)}
                style={{ minWidth: 300, flex: 1 }}
              />
              <TagCustomizer
                style={{ width: 130 }}
                screenSize={screenSize}
                tags={defaultValues?.tags}
                onChange={(value) => handleChange("tags", value)}
                chartType={defaultValues?.type}
              />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>

            <div className={"flex-item-small-screen"}>
              <Input
                className="input"
                placeholder={placeholderChartName}
                defaultValue={defaultValues?.name}
                onChange={(value) => handleChange("name", value)}
                style={{ minWidth: 150, flex: 1 }}
              />
              <ChartList
                className="chart-list"
                defaultValue={defaultValues?.type}
                onChange={(value) => handleChange("type", value)}
                style={{ minWidth: 150, flex: 1, height: "38px !important" }}
              />
            </div>

    
          {endpoints.length > 1 && (

              <div className={"flex-item-small-screen"}>
                <SelectPicker
                  label="Selected Tag List"
                  defaultValue={endpoints[listId].tags}
                  data={endpoints.map((endpoint) => ({
                    label: endpoint.tags,
                    value: endpoint.tags,
                  }))}
                  onChange={(value) => {
                    setList(
                      endpoints.findIndex((endpoint) => endpoint.tags === value)
                    );
                    handleChange("tags", []);
                  }}
                  style={{ minWidth: 200, flex: 1 }}
                />
              </div>

          )}
    

            <div className={"flex-item-small-screen"}>
              <TagList
                className="tag-list"
                defaultValue={defaultValues?.tags}
                endpoints={endpoints[listId]}
                tags={tagList}
                onChange={(value) => handleChange("tags", value)}
                style={{ minWidth: 200, flex: 1 }}
              />
              <div className="flex-item-small-screen">
                <TagCustomizer
                  style={{ minWidth: 130, marginRight: 10 }}
                  screenSize={screenSize}
                  tags={defaultValues?.tags}
                  onChange={(value) => handleChange("tags", value)}
                  chartType={defaultValues?.type}
                />
                <ToggleVisibilityButton
                  className="toggle-visibility-button"
                  defaultValue={defaultValues?.isPublic}
                  onClick={(value) => handleChange("isPublic", value)}
                  style={{ minWidth: 130 }}
                />
              </div>
            </div>

        </>
      );
    }
};

export default SharedOptions;