/* built-in packages */
import React, { useState, useContext, useEffect } from "react";

/* 3rd-party packages */
import { Modal, RadioGroup, Radio, CheckboxGroup, Checkbox, Button } from 'rsuite';
import axios from "axios";
import Select from "react-select";
import { NarrowScreenContext, SiteContext, UseAuthenticationContext } from "../context";

import { MsalContext } from "@azure/msal-react";

/* self-provided packages */
import {
  mergeObjects,
  makeAuthUrlWithHeaders,
  acquireToken
} from "../utils";
import { translate } from "../languages";

export default function ExcelExportModal(props) {
  const {
    onHide,
    show,
    settings,
    parseTags,
    params,
    excelExport
  } = props;

  const { isNarrowScreen }                    = useContext(NarrowScreenContext);
  const { site }                              = useContext(SiteContext);
  const { useAuthentication }                 = useContext(UseAuthenticationContext);
  const msalContext                           = useContext(MsalContext);

  const [exportForm, setExportForm]           = useState();
  const [exportOptions, setExportOptions]     = useState();
  const [loading, setLoading]                 = useState(false);
  const [updateTime, setUpdateTime]           = useState(new Date());

  const defaultForm                           = require('./defaultExcelExportForm.json')

  useEffect( () => {
    const exportStates = new Map();
    let inputs = excelExport?.inputs ? excelExport.inputs : defaultForm;
    // input states are "exportWindow", "exportGapFilling" and "exportFunctions"
    inputs.forEach(input => exportStates.set(input.state, input.defaultValue));
    setExportForm(inputs);
    setExportOptions(exportStates);
  },[excelExport, defaultForm]);

  const isDisabled = (value) => {
    if (Number.isInteger(value) && value > 0) { // only number values and greater than 0 (-1 resolution is auto, should be always available )
      const days = (new Date(params.edt).getTime() - new Date(params.sdt).getTime()) / 86400000;
      if (days > 1 && days <= 7) { // 1s disabled when time window > 1d 
        if (value <= 1) { return true; }
        else { return false; }
      } else if (days > 7 && days <= 30) { // 10s disabled when time window > 7d
        if (value <= 10) { return true; }
        else { return false; }
      } else if (days > 30 && days <= 180) { // 30s disabled when time window > 30d
        if (value <= 30) { return true; }
        else { return false; }
      } else if (days > 180) { // 60s disabled when time window > 180d
        if (value <= 60) { return true; }
      } else { return false; }
    } else { return false; }
  }

  const disableSelectOptions = (options) => {
    return options.map(option => { return {...option, isDisabled: isDisabled(option.value)} })
  }

  const exportExcel = async () => {
    let url = excelExport?.url ? excelExport.url : `/boa/api/v1/${site}/export`;
    let fileType = excelExport?.fileType ? excelExport?.fileType : "xlsx";
    let tags = "";
    let sdt = null;
    let edt = null;
    if (parseTags) {
      let queryParams = params.filter(param => param.tags); //use only quaryParams that have tags
      for (let i = 0; i < queryParams.length; i++) {
        if (Array.isArray(queryParams[i].tags)) { //multiple tags
          if (i + 1 === queryParams.length) { // no comma when last tag
            tags = tags + queryParams[i].tags.join();
          } else { // add comma if not last tag
            tags = tags + queryParams[i].tags.join() + ",";
          }      
        } else { // one tag
          if (i + 1 === queryParams.length) { // no comma when last tag
            tags = tags + queryParams[i].tags;
          } else { // add comma if not last tag
            tags = tags + queryParams[i].tags + ",";
          }      
        }
        sdt = queryParams[i].sdt;
        edt = queryParams[i].edt;
      }
      
    } else {
      tags = params.tags;
      sdt = params.sdt;
      edt = params.edt;
    }

    let filename = `BOA_Export_${tags}_${sdt}.${fileType}`;
    if (tags.split(",").length > 4){filename = `BOA_Export_${sdt}.${fileType}`}
    const mergedParams = mergeObjects(
      {
        sdt: sdt,
        edt: edt,
        tags,
        window: exportOptions.get("exportWindow"),
        method: exportOptions.get("exportGapFilling"),
        function: Array.isArray(exportOptions.get("exportFunctions")) ? exportOptions.get("exportFunctions").join() : exportOptions.get("exportFunctions"),
        resolution: exportOptions.get("exportResolution")
      },
      settings?.default_queryparams
    );
    let headers = {
      accept: "application/octet-stream",
      "content-type": "application/octet-stream"
    };
    if (useAuthentication) {
      [url, headers] = await makeAuthUrlWithHeaders(
        url, 
        headers, 
        acquireToken(msalContext)
      )
    }

    await axios({
      method: 'get',
      url: url,
      params: mergedParams,
      headers: headers,
      responseType: 'blob'
    }).then(response => {
      let objectUrl = URL.createObjectURL(response.data);
      setLoading(false);
      let downloadLink = document.createElement("a");
      downloadLink.href = objectUrl;
      downloadLink.download = filename;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    })
  }

  const createInputs = (options, index) => {
    return(
      <div key={"exportInput" + index}>
        {options.text &&
          <div className="mb-2 mt-4">
            {translate(options.text)}:
          </div>
        }

        {options.type === "dropdown" &&
          <Select 
            classNamePrefix = "react-select"
            defaultValue = { options.values.find(value => exportOptions.get(options.state) === value.value) }
            options = {disableSelectOptions(options.values)}
            onChange = { (e) => { setExportOptions(exportOptions.set(options.state, e.value)); setUpdateTime(new Date()); } }
            isSearchable = {false}
          />
        }

        {options.type === "select" &&
          <RadioGroup 
            defaultValue={exportOptions.get(options.state)} 
            name="radioList" 
            onChange={ (value) => { setExportOptions(exportOptions.set(options.state, value)); setUpdateTime(new Date()); } }
          >
            {options.values.map(option => <Radio key={option.value} value={option.value} disabled={isDisabled(option.value)}>{translate(option.label)}</Radio>)}
          </RadioGroup>
        }

        {options.type === "multiselect" &&
          <CheckboxGroup name="checkboxList" defaultValue={exportOptions.get(options.state)} onChange={ (value) => { setExportOptions(exportOptions.set(options.state, value)); setUpdateTime(new Date()); } }>
            {options.values.map(option => <Checkbox key={option.value} value={option.value} disabled={isDisabled(option.value)}> {translate(option.label)} </Checkbox> )}
          </CheckboxGroup>
        }
      </div>   
    );
  }

  const checkOptions = () => {
    return isDisabled(exportOptions.get("exportWindow")) || isDisabled(exportOptions.get("exportResolution"));
  }

  const getExcelExportModal = () => {
    return(
      <Modal size={isNarrowScreen ? "xs" : "sm"} open={show} overflow={false} onClose={ () => onHide() }>
        <Modal.Header>
          <Modal.Title>Excel Export</Modal.Title>
        </Modal.Header>
        <Modal.Body key={updateTime}>
          {exportForm && exportForm.map((input, index) => createInputs(input, index)) }


            <Button
              appearance="primary"
              color="green"
              style={{ marginTop: "20px" }}
              disabled={checkOptions()}
              onClick={()=> {
                exportExcel();
                setLoading(true);
              }}
            >
              {translate("Export")}
            </Button>

          {checkOptions() &&
            <div className="w-75 ps-2 d-inline-block text-danger">
              {translate("use a different time window / resolution")}
            </div>
          }
          {loading && (
            <div className="w-75 d-inline-block">
              <em className="me-2 ms-2 fas fa-spinner" id="loadingIcon" />
              {translate("excel_loading")}
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  }

  return exportOptions && getExcelExportModal();
}
