import React, { useState, useEffect } from 'react';
import { Button, Drawer, IconButton, Input, InputGroup } from 'rsuite';
import { translate } from "../languages";
import { getColors } from "../utils";

import EditIcon from '@rsuite/icons/Edit';
import ColorPicker from './colorPicker';
import YAxisHeightSelector from './yAxisHeightSelector';

// Tag format example:[
// [
//     "653-ka-478", (name)
//     "Operation Time", (description)
//     1, (index)
//     "-", (unit)
//     "#0000ff" (color)
//     {min: null, max: null} (min/max height)
// ]

// Drawer for customizing tag names, units, and colors
const TagCustomizer = (props) => {
  const { style, screenSize, tags: initialTags, onChange, chartType } = props;
  const [open, setOpen] = useState(false);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    setTags(initialTags);
  }, [initialTags]);

  // const colorPalette = [
  //   "#3399ff",   // Blue
  //   "#2b908f",   // Teal
  //   "#2ee0ca",   // Aqua
  //   "#90ed7d",   // Light Green
  //   "#abcc73",   // Soft Green
  //   "#ffd700",   // Gold
  //   "#f7a35c",   // Soft Orange
  //   "#fe6a35",   // Orange
  //   "#f15c80",   // Soft Red
  //   "#c42525",   // Red
  //   "#6E2594",   // Purple
  //   "#dda0dd",   // Lavender
  //   "#f032e6",   // Pink
  // ];

  const colorPalette = getColors().slice(0, 13);

  const updateTags = (key, value, index) => {
    setTags((prevTags) => {
      const updatedTags = [...prevTags];
      if (key === "color") {
        updatedTags[index][4] = value; // Update color
      } else if (key === "description") {
        updatedTags[index][1] = value; // Update description
      } else if (key === "unit") {
        updatedTags[index][3] = value; // Update unit
      } else if (key === "yAxisHeight") {
        updatedTags[index][5] = value; // Update yAxisHeight
      }

      return updatedTags;
    });
  };

  const saveChanges = () => {
    onChange(tags);
    setOpen(false);
  };

  return (
    <>
      <IconButton
        icon={<EditIcon className="white-icon" />}
        style={style}
        onClick={() => setOpen(true)}
      >
        {translate('Edit tags')}
      </IconButton>

      <Drawer
        open={open}
        size={screenSize < 585 ? 'full' : 'sm'}
        placement='bottom'
        onClose={() => setOpen(false)}
      >
        <Drawer.Header>
          <Drawer.Title>{translate('Tag editor')}</Drawer.Title>
          <Drawer.Actions>
            <Button onClick={() => saveChanges()} appearance="primary" color="green">
            {translate('Save')}
            </Button>
            <Button onClick={() => setOpen(false)} appearance="primary" color="red">{translate('Cancel')}</Button>
          </Drawer.Actions>
        </Drawer.Header>

        <Drawer.Body className='tag-editor-body'>
          {tags.length > 0 ? (
            <div>
              {tags.map((tag, index) => (
                <div key={index} className="flex-container">
                  <InputGroup>
                    <InputGroup.Addon>{translate('Description')}</InputGroup.Addon>
                    <Input
                      value={tag[1]}
                      onChange={(value) => updateTags("description", value, index)}
                    />
                  </InputGroup>

                  <InputGroup style={{flex: "0 1 120px"}}>
                    <InputGroup.Addon>{translate('Unit')}</InputGroup.Addon>
                    <Input
                      value={tag[3]}
                      onChange={(value) => updateTags("unit", value, index)}
                    />
                  </InputGroup>

                  {(chartType === "Line" || chartType === "Bar") && (
                  <YAxisHeightSelector
                    screenSize={screenSize}
                    defaultValues={initialTags[index]}
                    onChange={(value) => updateTags("yAxisHeight", value, index)}
                  />
                  )}

                  {(chartType !== "Gauge") && (
                    <div className="flex-item">
                      <ColorPicker 
                        colors={colorPalette}
                        defaultColor={tag[4] || colorPalette[index]}
                        onChange={(color) => updateTags("color", color, index)} 
                      />
                    </div>
                  )}
                  
                </div>
              ))}
            </div>
          ) : (
            <div>{translate("No tags selected")}</div>
          )}
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default TagCustomizer;
