import React, { useState, useContext, useEffect, createRef } from "react";
import {
  fetchDataHelper,
  generateGuid,
  hasOnlySpacesAndNewlines
} from "../utils";
import { html2json } from "html2json";
import { translate } from "../languages";

import { UseAuthenticationContext } from "../context";
import { MsalContext } from "@azure/msal-react";

/**
 * Component creates a document viewing element. The document reside
 * in the backend as raw HTML. This component contains methods for
 * extracting, parsing, and the visualizing the HTML.
 */

export default function EmbeddedDocs(props) {
  const { folder } = props;

  const msalContext                         = useContext(MsalContext);
  const { useAuthentication }               = useContext(UseAuthenticationContext);

  const [navigation, setNavigation]         = useState();
  const [content, setContent]               = useState();

  // document prop has the folder name where document is located, default value can be modified with folder object
  const documentFolder = typeof(folder) === "string" ? `/boa/documents/${folder}/` : `/${folder.folder}/`;

  useEffect( () => { //Fetch navigation
    try {
      fetchDataHelper({
        url: documentFolder,
        useAuthentication: useAuthentication,
        authContext: msalContext,
        onSuccess: response => setNavigation(response.data)
      });
    } catch(error) { console.log(error.message); }
  },[documentFolder, useAuthentication, msalContext])

  const getDoc = (docPath) => { //fetch content
    docPath = removeRelativePathNotation(docPath);
    try {
      fetchDataHelper({
        url: `${documentFolder}${docPath}`,
        useAuthentication: useAuthentication,
        authContext: msalContext,
        onSuccess: response => setContent(response.data)
      });
    } catch(e) { console.log(e.message); }
  }


  /**
   * Parse response, which is essentially HTML, to
   * JSON structure for easier parsing
   * @param {object} response response object
   * @returns {object} JSON-like object
   */
  const parseHTMLResponse = (response) => {
    const parser = new DOMParser();
    const data = parser.parseFromString(response, "text/html");
    const bodyHTML = data?.body?.innerHTML;
    const jsonifiedBodyHTML = html2json(bodyHTML);
    const parsedHTML = parseJsonifiedHTML(jsonifiedBodyHTML);
    return parsedHTML;
  }

  /**
   * Remove relative path notation from the beginning
   * of a string. These notations are './' and '../'.
   * @param {string} path path
   * @returns {string} path with omitted relative path notation
   */
  const removeRelativePathNotation = (path) => {
    while (path.startsWith("./") || path.startsWith("../")) {
      if (path.startsWith("../")) {
        path = path.substring(3);
      } else if (path.startsWith("./")) {
        path = path.substring(2);
      }
    }
    return path;
  }


  /**
   * Fetch image from backend
   * @param {string} imgPath path to image
   * @param {function} callback callback function to set image to DOM
   */
  const getImg = (imgPath, callback) => {
    try {
      fetchDataHelper({
        url: `${documentFolder}${imgPath}`,
        headers: {},
        responseType: "blob",
        useAuthentication: useAuthentication,
        authContext: msalContext,
        onSuccess: callback
      })
    } catch(e) { console.log(e.message); }
  }

  /**
   * Parse jsonified HTML recursively and construct a nested DOM element
   * @param {object} jsonifiedHTML html that has gone through html2json conversion
   * @returns {JSX.Element} HTML element
   */
  const parseJsonifiedHTML = (jsonifiedHTML) => {
    if (jsonifiedHTML.node === "root") {
      return (
        <div key={generateGuid()}>
          {jsonifiedHTML.child.map(item => parseJsonifiedHTML(item))}
        </div>
      );
    } else if (jsonifiedHTML.node === "text") {
      if (hasOnlySpacesAndNewlines(jsonifiedHTML.text)) return null;

      return (
        <div
          className="embedded-docs-text"
          key={generateGuid()}
        >
          {jsonifiedHTML.text}{"\u00A0"}
        </div>
      );
    } else if (jsonifiedHTML.node === "element") {
      if (jsonifiedHTML.tag === "div") {
        return (
          <div key={generateGuid()}>
            {jsonifiedHTML.child?.map(item => parseJsonifiedHTML(item))}
          </div>
        );
      } else if (jsonifiedHTML.tag === "main") {
        return (
          <main key={generateGuid()}>
            {jsonifiedHTML.child.map(item => parseJsonifiedHTML(item))}
          </main>
        );
      } else if (jsonifiedHTML.tag === "article") {
        return (
          <article key={generateGuid()}>
            {jsonifiedHTML.child.map(item => parseJsonifiedHTML(item))}
          </article>
        );
      } else if (jsonifiedHTML.tag === "section") {
        return (
          <section key={generateGuid()}>
            {jsonifiedHTML.child.map(item => parseJsonifiedHTML(item))}
          </section>
        );
      } else if (jsonifiedHTML.tag === "nav") {
        return (
          <nav key={generateGuid()}>
            {jsonifiedHTML.child.map(item => parseJsonifiedHTML(item))}
          </nav>
        );
      } else if (jsonifiedHTML.tag === "p") {
        return (
          <p
            className="embedded-docs-text"
            key={generateGuid()}
          >
            {jsonifiedHTML?.child?.[0]?.text}
          </p>
        );
      } else if (jsonifiedHTML.tag === "h1") {
        return (
          <h1 key={generateGuid()}>
            {jsonifiedHTML.child.map(item => parseJsonifiedHTML(item))}
          </h1>
        );
      } else if (jsonifiedHTML.tag === "h2") {
        return (
          <h2 key={generateGuid()}>
            {jsonifiedHTML?.child?.map(item => parseJsonifiedHTML(item))}
          </h2>
        );
      } else if (jsonifiedHTML.tag === "li") {
        return (
          <li key={generateGuid()}>
            {jsonifiedHTML.child.map(item => parseJsonifiedHTML(item))}
          </li>
        );
      } else if (jsonifiedHTML.tag === "ul") {
        return (
          <ul key={generateGuid()}>
            {jsonifiedHTML.child.map(item => parseJsonifiedHTML(item))}
          </ul>
        )
      } else if (jsonifiedHTML.tag === "ol") {
        return (
          <ol key={generateGuid()}>
            {jsonifiedHTML?.child?.map(item => parseJsonifiedHTML(item))}
          </ol>
        );
      } else if (jsonifiedHTML.tag === "a") {
        return (
          <div
            className="embedded-docs-clickable"
            key={generateGuid()}
            onClick={() => getDoc(jsonifiedHTML.attr.href)}
          >
            {jsonifiedHTML.child.map(item => parseJsonifiedHTML(item))}
          </div>
        );
      } else if (jsonifiedHTML.tag === "span") {
        if (
          jsonifiedHTML.child?.length === 1
          && jsonifiedHTML.chlid?.[0]?.text
        ) {
          return (
            <b key={generateGuid()}>
              {jsonifiedHTML.child[0].text}
            </b>
          )
        } else {
          return (
            <span className="embedded-docs-span" key={generateGuid()}>
              {jsonifiedHTML?.child?.map(item => parseJsonifiedHTML(item))}
            </span>
          );
        }
      } else if (jsonifiedHTML.tag === "table") {
        return (
          <table key={generateGuid()}>
            {jsonifiedHTML?.child?.map(item => parseJsonifiedHTML(item))}
          </table>
        );
      } else if (jsonifiedHTML.tag === "caption") {
        return (
          <caption key={generateGuid()}>
             {jsonifiedHTML?.child?.map(item => parseJsonifiedHTML(item))}
          </caption>
        );
      } else if (jsonifiedHTML.tag === "colgroup") {
        return (
          <colgroup key={generateGuid()}>
            {jsonifiedHTML?.child ? jsonifiedHTML?.child?.map(item => parseJsonifiedHTML(item)) : null}
          </colgroup>
        );
      } else if (jsonifiedHTML.tag === "col") {
        const styles = jsonifiedHTML?.attr?.style.split(";");
        const styleProperties = styles.map(style => style.split(":"));
        const camelCaseProperties = [];
        for (let styleProperty of styleProperties) {
          const propertyName = styleProperty[0];
          const propertyValue = styleProperty[1];
          const camelCaseName = propertyName.split("-").map((name, index) => { //background-color => [background, color]
            if (index > 0) {
              return name.charAt(0).toUpperCase() + name.slice(1); // color => Color
            } else {
              return name;
            }
          }).join(""); //backgroundColor
          camelCaseProperties.push({ property: camelCaseName, value: propertyValue });
        }
        const styleObject = JSON.parse(`{${camelCaseProperties.map(style => `"${style.property}":"${style.value}"`).join(", ")}}`);
        return (
          <col 
            key={generateGuid()} 
            colSpan={jsonifiedHTML?.attr?.colspan} 
            style={styleObject} 
          />
        );
      } else if (jsonifiedHTML.tag === "tbody") {
        return (
          <tbody key={generateGuid()}>
            {jsonifiedHTML?.child?.map(item => parseJsonifiedHTML(item))}
          </tbody>
        );
      } else if (jsonifiedHTML.tag === "tr") {
        return (
          <tr key={generateGuid()}>
            {jsonifiedHTML?.child?.map(item => parseJsonifiedHTML(item))}
          </tr>
        )
      } else if (jsonifiedHTML.tag === "td") {
        return (
          <td key={generateGuid()}>
            {jsonifiedHTML?.child?.map(item => parseJsonifiedHTML(item))}
          </td>
        );
      } else if (jsonifiedHTML.tag === "strong") {
        return (
          <b key={generateGuid()}>
            {jsonifiedHTML?.child?.map(item => parseJsonifiedHTML(item))}
          </b>
        );
      } else if (jsonifiedHTML.tag === "dl") {
        return (
          <dl key={generateGuid()}>
            {jsonifiedHTML?.child?.map(item => parseJsonifiedHTML(item))}
          </dl>
        );
      } else if (jsonifiedHTML.tag === "dt") {
        return (
          <dt key={generateGuid()}>
            {jsonifiedHTML?.child?.map(item => parseJsonifiedHTML(item))}
          </dt>
        );
      } else if (jsonifiedHTML.tag === "dd") {
        return (
          <dd key={generateGuid()}>
            {jsonifiedHTML?.child?.map(item => parseJsonifiedHTML(item))}
          </dd>
        );
      } else if (jsonifiedHTML.tag === "figure") {
        return (
          <figure key={generateGuid()}>
            {jsonifiedHTML?.child?.map(item => parseJsonifiedHTML(item))}
          </figure>
        );
      } else if (jsonifiedHTML.tag === "img") {
        const imgRef = createRef();
        const imgElement = (
          <img
            className="embedded-docs-img"
            ref={imgRef}
            key={generateGuid()}
            src={null}
            alt=""
            width={jsonifiedHTML.attr?.width}
            height={jsonifiedHTML.attr?.height}
          />
        );
        const imgPath = removeRelativePathNotation(jsonifiedHTML?.attr?.src);
        let imageType = imgPath.split(".svg").length > 1 ? "image/svg+xml" : "image/jpeg";

        getImg(
          imgPath,
          response => {
            const img = URL.createObjectURL(new Blob([response.data], { type: imageType }))
            imgRef.current.src = img;
          }
        );

        return imgElement;
      }
    }
  }

  return (
    <div className="embedded-docs-container row">

      <div className="embedded-docs-content embedded-docs-toc col-12 col-xl-3">
        {navigation
          ? parseHTMLResponse(navigation)
          : <div><h3>{translate("Loading")}</h3></div>
        }
      </div>

      <div className="embedded-docs-content embedded-docs-doc col-12 col-xl-9">
        {content 
          ? parseHTMLResponse(content) 
          : <div><h3>{translate("Select document from the left")}</h3></div>
        }
      </div>

    </div>
  );
}
