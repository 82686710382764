import React, { useState, useRef, useContext, useEffect, useCallback } from "react";
import { MsalContext } from "@azure/msal-react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import DraggableGrid from 'ruuri';
import { io } from "socket.io-client";
import { Message, toaster } from 'rsuite';
import { Item, CustomReport, GridSelection } from ".";
import { AndritzLogo } from "../logo";
import { Breadcrumb } from "../breadcrumb";
import { TagSearchTool } from "../tags";
import { SiteNav } from "../menu";
import { BottomBar } from "../bottomBar";
import { EmbeddedDocs } from "../documentation";
import { DataImport, Fileman } from "../filehandling";
import { GeneratedReportsView } from "../reporting";
import { translate, useTranslateToString } from "../languages";
import { useIntl } from 'react-intl';
import {
  UseAuthenticationContext,
  NarrowScreenContext,
  SiteContext,
} from "../context";
import {
  makeAuthUrlWithHeaders,
  logErrorIfDevelopmentMode,
  acquireToken,
  useIsAuthenticated
} from "../utils";

import fetchCustomPanels from "../customPanel/gridLogic/fetchCustomPanels";
import setPanelValues from "../customPanel/gridLogic/setPanelValues";

export default function SelectedGrid(props) {
  const { 
    site_info, 
    layouts, 
    menuItems, 
    siteChangelog, 
    match, 
    endpoints,
    resolutions,
    customTemplates
  } = props;

  const { site }                            = useContext(SiteContext);
  const { isNarrowScreen }                  = useContext(NarrowScreenContext);
  const { useAuthentication }               = useContext(UseAuthenticationContext);
  const msalContext                         = useContext(MsalContext);

  const intl                                = useIntl();
  const history                             = useHistory();

  const [timeValue, setTimeValue]                                         = useState(0); // Hook 1
  const [draggable, setDraggable]                                         = useState(false); // Hook 2
  const [refreshTime, setRefreshTime]                                     = useState(new Date()); // Hook 3 used in reset panels
  const [globalTime, setGlobalTime]                                       = useState(); // Hook 4 
  const [globalResolution, setGlobalResolution]                           = useState(); //  Hook 5
  const [displayGlobalTimeFilter, setDisplayGlobalTimeFilter]             = useState(); //Hook 6
  const [minimizedPanels, setMinimizedPanels]                             = useState({}); //Hook 7
  const [metakeys, setMetakeys]                                           = useState(); // Hook 8
  const [visibilitySettings, setVisibilitySettings]                       = useState(new Map([])); // Hook 9
  const [collapsed, toggleCollapsed]                                      = useState(false); // Hook 10
  const [app, setApp]                                                     = useState(); // Hook 11
  const [panels, setPanels]                                               = useState(); // Hook 12
  const [customPanels, setCustomPanels]                                   = useState(); // Hook 13
  const [modifiedTemplates, setModifiedTemplates]                         = useState(customTemplates); // Hook 14

  const isAuthenticated = useIsAuthenticated();
  const socketData = useRef();
  const muuriRef = useRef();

  const showCustomPanelButton = Array.isArray(app?.content?.content) || app?.active === "custom_panels" ? true : false;

  /* Function to set global time values (sdt, edt, timeWindow, extraTime, skipTimeCounter) when time is selected in breadcrumb component */
  const updateGlobalTime = useCallback((values) => { setGlobalTime(values) },[])

  /* When page changes, app state is updated 
   * app has the information of the selected page its content and possible sub menus that are used to render components 
   * global time & global resolution is also reset to null
   */

  useEffect(() => {
    /* /boa/site/piers/pier1/positions */
    let item = match.params.item; //piers
    let child = match.params.child_item; //pier1
    let subChild = match.params.child_child_item; //positions

    /* If landingPage is defined in siteNav.json and user is in root path (after site is selected), 
     * set item, child and subChild to match url defined in landingPage 
     */
    if (menuItems.landingPage && history && match.url === "/") { 
      history.push(`/${site}/${menuItems.landingPage}`);
      [item, child, subChild] = menuItems.landingPage.split("/");
    }

    if (menuItems.apps[item]) { // menuItems.apps contains siteNav.json objects that are needed to get app info
      if (subChild) {
        setApp({
          active: `${item}/${child}/${subChild}`, 
          content: menuItems.apps[item].children[child].children[subChild], 
          subMenu: `${item}/${child}`
        });
      } else if (child) {
        setApp({
          active: `${item}/${child}`, 
          content: menuItems.apps[item].children[child], 
          subMenu: item
        });
      } else {
        setApp({
          active: item, 
          content: menuItems.apps[item], 
          subMenu: item
        })
      }
    } else if (item === "favorites") { // favorites are not part of siteNav.json config so we need to generate app info manually
      setApp({
        active: "Favorites",
        content: {
          "text": "Favorites",
          "icon": "fas fa-star",
          "content": [JSON.parse(localStorage.getItem(`${site}-favorites`))]
        },
        subMenu: "Favorites"
      });
    } else if (item === "custom_panels") {
      setApp({
        active: "custom_panels",
        content: {
          "text": "Custom Panels",
          "icon": "fas fa-cogs",
          "content": "custom_panels"
        },
        subMenu: "custom_panels"
      });
    } else { // if item is undefined user is in grid selection
      setApp({
        active: "GridSelection", 
        content: null, 
        subMenu: "GridSelection"
      })
    }
    setGlobalTime();
    setGlobalResolution();
  }, [match, menuItems, site, history] );
  // Get customPanels from backend or local storage
    useEffect(() => {
      if (showCustomPanelButton) {
        fetchCustomPanels(msalContext, isAuthenticated, site, app.active).then((customPanels) => {
          setCustomPanels(customPanels);
          for (let panel of customPanels) {
            if (panel.properties?.lineOptions || panel.properties?.barOptions) {setMetakeys(["Tables", "Charts"])}
          }
        });
      }
    }, [isAuthenticated, msalContext, site, app, showCustomPanelButton]);

    // Add info text to demo panel
    const demoTitleTranslation = useTranslateToString("This is a custom panel");
    const demoTextTranslation = useTranslateToString("You can create custom panels like these by clicking the 'Create custom panel' button. Select the panel type and configure it to your needs.");
  
    useEffect(() => {
      // Create a copy of customTemplates
      const templatesCopy = { ...customTemplates };
      if (customPanels?.[0]?.isDemo === undefined || !customPanels[0]?.isDemo) {
    
        // Remove caption and subtitle
        delete templatesCopy.Line.rows.chart_0.subtitle;
        delete templatesCopy.Line.rows.chart_0.caption;
    
        setModifiedTemplates(templatesCopy);
        return;
      }
  
      templatesCopy.Line.rows.chart_0.subtitle = {
        text: demoTitleTranslation,
        style: {
          fontSize: '20px',
        }
      }

      templatesCopy.Line.rows.chart_0.caption = {
        text: demoTextTranslation,
        align: 'center',
        verticalAlign: 'bottom',
        y: 10,
        useHTML: true,
        style: {
            fontSize: '14px',
            maxWidth: '800px',
            whiteSpace: 'normal'
        }
      }
        setModifiedTemplates(templatesCopy);
    }, [customTemplates, customPanels, demoTextTranslation, demoTitleTranslation]);

  /* When page is selected/changed breadcrumb can be rendered */
  useEffect(() => {
    /* Check if there are minimized panels so dropdown menu can be added if needed */ 
    if (localStorage.getItem("minimizedPanels")) { setMinimizedPanels(JSON.parse(localStorage.getItem("minimizedPanels"))); }
    /* Check if content has panels 
    * If panels exist check if they have meta keys and add them to array that is used to render toggle buttons
    * also enable global time filters and enable dragging when content is panels */
    if (app && app.content && layouts) {
      if (Array.isArray(app.content.content)) {
        let panelNames = app.content.content;
        let metakeys = [];
        for (let panelName of panelNames) {
          if (layouts[panelName]?.metakeys) { // Metakeys are in panels
            layouts[panelName].metakeys.forEach(item => {
              if (metakeys.indexOf(item) === -1) {metakeys.push(item); }
            });
          }
          if (layouts[panelName]?.rows) { // Metakeys are in row objects (table, chart)
            for (let rowItem in layouts[panelName].rows) {
              if (layouts[panelName].rows[rowItem]?.metakeys) {
                layouts[panelName].rows[rowItem].metakeys.forEach(item => {
                  if (metakeys.indexOf(item) === -1) {metakeys.push(item); }
                });
              }
            }
          } else if (layouts[panelName]?.tabs) { // Metakeys are in row objects (table, chart) but panel has tabs
            for (let tab in layouts[panelName].tabs) {
              for (let rowItem in layouts[panelName].tabs[tab]) {
                if (layouts[panelName].tabs[tab][rowItem]?.metakeys) {
                  layouts[panelName].tabs[tab][rowItem].metakeys.forEach(item => {
                    if (metakeys.indexOf(item) === -1) {metakeys.push(item); }
                  });
                }
              }
            }
          }          
        }
        setMetakeys(metakeys);
        setDisplayGlobalTimeFilter(true);
        if (!isNarrowScreen) setDraggable(true);
      } else { // if content is not panels there shouldn't be meta keys, global time buttons or dragging
        setMetakeys([]);
        setDisplayGlobalTimeFilter(false);
        setDraggable(false);
      }
    }
  }, [app, layouts, isNarrowScreen]);

  useEffect(()=> {
    const createSocketConnection = async() => {
      /* init socket.io client and save it to socketData ref*/  
      let modules = app?.content?.content;
      if (modules) {
        let socket = modules.ws;
        if (socket) {
          const socketApi = socket.ws.api;
          let headers = {};
          let url = `/boa/api/v1/${socketApi}/ws/opcua-ws`;
          let method = "get";
          /* Modify request URL in case authentication is used */
          if (useAuthentication) {
            [url, headers] = await makeAuthUrlWithHeaders(
              url, 
              headers, 
              acquireToken(msalContext)
            )
            try {
              await axios({
                method,
                url,
                headers
              })
              .then(() => {
                let boaurl = window.location.origin;
                /* Replace 'localhost' with the correct dev URI */
                if (
                  window.location.hostname === "localhost"
                  || window.location.hostname === "127.0.0.1"
                ) {
                  boaurl = process.env.REACT_APP_DEV_URI;
                }

                socketData.current = io(boaurl);
                toaster.push(
                  <Message type="success">
                    {intl.formatMessage({ id: ("live connection established")})}
                  </Message>
                );
                });
            } catch(e) {
              logErrorIfDevelopmentMode(e);
            }
          } else { //no authentication
            if (socket) {
              const socketUrl = socket.panels.properties.ws.url;
              try {
                socketData.current = io(socketUrl);
                toaster.push(
                  <Message type="success">
                  {intl.formatMessage({ id: ("live connection established")})}
                  </Message>
                );
              } catch(e) {
                logErrorIfDevelopmentMode(e);
              }
            }
          }
        }
      }
    }

    createSocketConnection();
    return () => {
      if (socketData.current) { socketData.current.disconnect(); }
    }
  },[msalContext, app, useAuthentication, intl])

  /* When page is selected/changed set the page content */
  useEffect(() => {
    /* function to handle favorites adding and removing
    * if panel is already included in favorites, remove it
    * if not, add it */
    const handleFavorites = (item) => {
      let favorites = Array.isArray(JSON.parse(localStorage.getItem(`${site}-favorites`))) ? JSON.parse(localStorage.getItem(`${site}-favorites`)) : [];
      if (favorites.includes(item)) {
        const index = favorites.indexOf(item);
        if (index > -1) { favorites.splice(index, 1)}
      } else {
        favorites.push(item)
      }
      localStorage.setItem(`${site}-favorites`, JSON.stringify(favorites));
      if (app.active === "Favorites") {
        setApp({ //update favorites
          active: "Favorites", 
          content: {
            "text": "Favorites",
            "icon": "fas fa-star",
            "content": favorites
          }, 
          subMenu: "Favorites"
        });
      } else {
        setApp((prevApp) => ({...prevApp, updated: new Date()})); //add updated value just to refresh "Add" / "Remove" texts 
      }
    }

    const isPanelMinimized = (layout, panelId) => {
      let isPanelMinimized = false;
    
      if (layout && panelId && layout in minimizedPanels) {
        isPanelMinimized = minimizedPanels[layout].some(panel => panel.id === panelId);
      }
    
      return isPanelMinimized;
    }
    
  
    /* Minimize functions below */
    const minimizePanel = (panelInfo) => {
      const layout = panelInfo.layout;
      const panel = { id: panelInfo.id, label: panelInfo.panel };
    
      if (layout && panel.id && panel.label) {
        const newMinimizedPanels = { ...minimizedPanels };
        if (
          layout in newMinimizedPanels
          && Array.isArray(newMinimizedPanels[layout])
        ) {
          newMinimizedPanels[layout].push(panel);
        } else {
          newMinimizedPanels[layout] = [panel];
        }
        setMinimizedPanels(newMinimizedPanels);
        localStorage.setItem(
          "minimizedPanels",
          JSON.stringify(newMinimizedPanels)
        );
      }
    }

    const excelExport = () => {
      if (menuItems.excelExport){ return menuItems.excelExport }
      else if (site_info.exporting){ return true }
      else { return false }
    }

    const renderPanels = []

    const parseMissingModule = (module) => {
      return(
        <div className="card item missing_module">
          <div className="card-title">
            <div className="boa-card-header">
              {translate("missing_module")}
            </div>
          </div>
          <div className="item-content">
          <div className="ms-2 mt-2">{module} - {translate("missing_module")}</div>
          </div>
        </div>  
      );
    };

    if (app && app.content) {
      document.title=`${app.content.text} | MetrisBOA`;
      const time = timeValue;

      //let socket = panels?.properties?.ws;
      let socket = null;

      if (showCustomPanelButton) {
        // This code block iterates over each custom panel. 
        // For each panel, it finds a matching layout in 'customTemplates'.
        // Then template is updated with the custom panel properties.
        if (modifiedTemplates && customPanels?.length > 0) {
          customPanels.forEach(item => {
              let layout = Object.keys(modifiedTemplates).find(key => key === item.properties.sharedOptions.type);
              if (layout) {
                  // setPanelValues is found at /src/customPanel/gridLogic/setPanelValues.js
                  let template = setPanelValues(JSON.parse(JSON.stringify(modifiedTemplates)), item, app.content.global_resbuttons, layout, resolutions);
                  let visible = template?.visible ?? true;
                  if (template?.metakeys) { // if panel has metakeys check visibility
                      for (let key of template[layout].metakeys) {
                          if (visibilitySettings.get(key) === false) {
                              visible = false;
                          }
                      }
                  }
            
                  const isMinimized = isPanelMinimized(
                      app.active,
                      template?.id
                  );

                  if (!isNarrowScreen) setDraggable(true);

                  if (visible && !isMinimized) {
                      renderPanels.push({
                          key: layout, 
                          id: item.id,
                          panelname: template.id,
                          elem: <Item
                              key={`${app.active}__${layout}`}
                              module={`${app.active}__${layout}`}
                              panel={template}
                              code={layout}
                              panelName={template.id}
                              time={time}
                              layouts={template}
                              menuItems={menuItems}
                              guides_endpoint={menuItems.guides_endpoint}
                              globalResolutionFilterValue={globalResolution}
                              handleTimeButtonTimeChange={ (timeValue) => { setTimeValue(timeValue);} }
                              minimizedPanels={minimizedPanels}
                              minimizePanel={minimizePanel}
                              socket_values={socketData}
                              socket={socket}
                              excelExport={excelExport()}
                              collapsedMenu={collapsed}
                              activeApp={app.active}
                              ruuriId={`${app.active}_${layout}`}
                              muuriRef = {muuriRef}
                              handleFavorites = { (q) => handleFavorites(q)}
                              visibilitySettings = {visibilitySettings}
                              globalTime={globalTime}
                              appContent={app.content}
                              className="card-title draggable"
                              endpoints={endpoints}
                              resolutions={resolutions}
                          />
                      });
                  }
              }
          });
        }
      }

      if (Array.isArray(app.content.content) && layouts) {
        // This loop is run at favorites
        if (Array.isArray(app.content.content[0])) {
            app.content.content[0].forEach(layout => {
              if (layouts[layout]) {
                let visible = layouts[layout].visible ?? true;
                if (layouts[layout]?.metakeys) { // if panel has metakeys check visibility
                  for (let key of layouts[layout].metakeys) {
                    if (visibilitySettings.get(key) === false) {
                      visible = false;
                    }
                  }
                }
                const isMinimized = isPanelMinimized(
                  app.active,
                  layouts?.[layout]?.title
                );
                
                if (visible && !isMinimized) {
                  renderPanels.push({key: layout, id: `${app.active}_${layout}`, panelname: layout, elem:
                    <Item
                      key={`${app.active}__${layout}`}
                      module={`${app.active}__${layout}`}
                      panel={layouts[layout]}
                      code={layout}
                      panelName={layout}
                      time={time}
                      layouts={layouts}
                      menuItems={menuItems}
                      guides_endpoint={menuItems.guides_endpoint}
                      globalResolutionFilterValue={globalResolution}
                      handleTimeButtonTimeChange={ (timeValue) => { setTimeValue(timeValue);} }
                      minimizedPanels={minimizedPanels}
                      minimizePanel={minimizePanel}
                      socket_values={socketData}
                      socket={socket}
                      excelExport={excelExport()}
                      collapsedMenu={collapsed}
                      activeApp={app.active}
                      ruuriId={`${app.active}_${layout}`}
                      muuriRef = {muuriRef}
                      handleFavorites = { (q) => handleFavorites(q)}
                      visibilitySettings = {visibilitySettings}
                      globalTime={globalTime}
                    />
                });
                }
              }
            });
        } else {
          app.content.content.forEach(layout => {
            if (layouts[layout]) {
              let visible = layouts[layout].visible ?? true;
              if (layouts[layout]?.metakeys) { // if panel has metakeys check visibility
                for (let key of layouts[layout].metakeys) {
                  if (visibilitySettings.get(key) === false) {
                    visible = false;
                  }
                }
              }
              const isMinimized = isPanelMinimized(
                app.active,
                layouts?.[layout]?.title
              );
              
              if (visible && !isMinimized) {
                renderPanels.push({key: layout, id: `${app.active}_${layout}`, panelname: layout, elem:
                  <Item
                    key={`${app.active}__${layout}`}
                    module={`${app.active}__${layout}`}
                    panel={layouts[layout]}
                    code={layout}
                    panelName={layout}
                    time={time}
                    layouts={layouts}
                    menuItems={menuItems}
                    guides_endpoint={menuItems.guides_endpoint}
                    globalResolutionFilterValue={globalResolution}
                    handleTimeButtonTimeChange={ (timeValue) => { setTimeValue(timeValue);} }
                    minimizedPanels={minimizedPanels}
                    minimizePanel={minimizePanel}
                    socket_values={socketData}
                    socket={socket}
                    excelExport={excelExport()}
                    collapsedMenu={collapsed}
                    activeApp={app.active}
                    ruuriId={`${app.active}_${layout}`}
                    muuriRef = {muuriRef}
                    handleFavorites = { (q) => handleFavorites(q)}
                    visibilitySettings = {visibilitySettings}
                    globalTime={globalTime}
                    site_info={site_info}
                  />
              });
              }
            }
          });
        }
      } else if (app.content.content.tagSearch) {       
        renderPanels.push({key: "tagSearch", id: "TagSearch", panelname: "TagSearch", elem:
          <TagSearchTool
            key={`TagTable-${app.active}`}
            settings={app.content.content.tagSearch}
            app={app}
            calcsAPI={menuItems.calcsAPI}
            excelExport={excelExport()}
          />
        });
      } else if (app.content.content === "fileman") {
        renderPanels.push({key: "fileman", id: "fileman", panelname: "fileman", elem: <Fileman />});
      } else if (app.content.content.websites) {
        for (let website of app.content.content.websites) {
          let menuWidth = collapsed ? window.innerWidth - 100 : window.innerWidth - 275;
          let width;
          if (website.width) { width = website.width; }
          else {
            if (isNarrowScreen) { width = window.innerWidth * 0.93; } 
            else {
              width = menuWidth * 0.97;
            }
          }
          let height = website.height ?? window.innerHeight * 0.8;
          let title = website.title ?? app.active;
          renderPanels.push({key: website.url, id: website.url, panelName: website.url, elem:
            <div key={website.url} className="card item" style={{ width: width}}>
              <div className="card-title">
                <div className="boa-card-header">
                  {translate(title)}
                </div>
              </div>
              <div className="item-content card-body">
                <iframe
                  key={website.url}
                  title={website.url}
                  src={website.url}
                  style={{ width: "100%", height: height }}
                />
              </div>
            </div>  
        });
        }
      } else if (app.content.content.embeddedDocs) {
        renderPanels.push({key: "embeddedDocs", id: "embeddedDocs", panelname: "embeddedDocs", elem:
          <EmbeddedDocs
            key="embedded-docs"
            folder={app.content.content.embeddedDocs}
          />
        });
      } else if (app.content.content.dataImport) {
        renderPanels.push({key: "dataImport", id: "dataImport", panelname: "dataImport", elem:
          <DataImport
            key="file_import"
            endpoint={app.content.content.dataImport}
          />
        });
      } else if (app.content.content.customReports){
        for (let customReport of app.content.content.customReports) {
          renderPanels.push({key: customReport.title, id: customReport.title, panelname: customReport.title, elem:
            <CustomReport
              key={"customReport" + customReport.title}
              module={customReport}
            />
          });
        }
      } else if (app.content.content.generatedReports) {
          renderPanels.push({key: "generatedReports", id: "generatedReports", panelname: "generatedReports", elem:
            <GeneratedReportsView
              key="generatedReportsView"
              endpoints={app.content.content.generatedReports}
              site={site}
            />
          });
      } else if (!showCustomPanelButton) {
        renderPanels.push({key: app.content.text, id: app.content.text, panelname: app.content.text, elem: parseMissingModule(app.content.text)});
      }
    }

    if (app?.active) {
      const customPanelOrder = JSON.parse(localStorage.getItem(`${site}-${app.active}-order`));
      if (customPanelOrder) {
          renderPanels.sort((a, b) => {
              return customPanelOrder.indexOf(a.id) - customPanelOrder.indexOf(b.id);
          });
      }
    }

    setPanels(renderPanels);

  }, [
    app, 
    collapsed, 
    globalResolution, 
    isNarrowScreen, 
    layouts, 
    modifiedTemplates, 
    menuItems, 
    minimizedPanels, 
    site, 
    timeValue, 
    visibilitySettings, 
    site_info, 
    globalTime, 
    customPanels, 
    endpoints, 
    showCustomPanelButton,
    resolutions
  ]);


  /** Function receives the name of pressed toggle button.
   * visibilitySettings state saves the names of pressed buttons and their new boolean values.
   */
  const togglePanels = (panel) => {
    if (typeof visibilitySettings.get(panel) === "boolean") { 
      let newState = visibilitySettings.set(panel, !visibilitySettings.get(panel));
      setVisibilitySettings(new Map(newState));
    } else {
      let newState = visibilitySettings.set(panel, false);
      setVisibilitySettings(new Map(newState)); }
  }
  
  const unMinimizePanel = (panelInfo) => {
    const { layout, panel: panelId } = panelInfo;
    if (layout && panelId) {
      const newMinimizedPanels = { ...minimizedPanels };
      if (
        layout in newMinimizedPanels
        && Array.isArray(newMinimizedPanels[layout])
      ) {
        newMinimizedPanels[layout] = newMinimizedPanels[layout].filter(panel => panel.id !== panelId);
        if (newMinimizedPanels[layout].length === 0) {
          delete newMinimizedPanels[layout];
        }
        setMinimizedPanels(newMinimizedPanels);
        localStorage.setItem(
          "minimizedPanels",
          JSON.stringify(newMinimizedPanels)
        );
      }
    }
  }

  // Sort and save favourite and custom panel order to local storage
  const savePanelOrder = () => {
    const muuriItems = muuriRef.current.grid.getItems();
  
    const panelOrder = [];
  
    // Extracting the panel IDs
    muuriItems.forEach((item) => {
      let panelID = item.getElement().firstChild.firstChild.getAttribute("id");
      //panelID = panelID.replace(/^Favorites_/, ""); // bandaid solution to fix the bug where favorites are not shown after rearranging 
      panelOrder.push(panelID);
    });

    if (app?.active) {
        localStorage.setItem(`${site}-${app.active}-order`, JSON.stringify(panelOrder));
    }
  };

  const showBottomBar = () => {
    if (useAuthentication) { return true; }
    else if (menuItems.bottomBar) { return true; }
    else { return false; }
  }

  if (app) {
    let sectionClass;
    if (Array.isArray(app.content?.content)){ sectionClass = "grid-section"} // basic panels
    else if (typeof(app.content?.content) === "string"){ sectionClass = `grid-section ${app.content.content}` } // content without configuration e.g. fileman
    else if (app?.content?.content){ sectionClass = `grid-section ${Object.keys(app.content.content)[0]}`; } // other content like tag search

    return(
      <section
        key={app.active + refreshTime + draggable}
        className={sectionClass}
        data-testid="selected-grid-section"
      >
        <Breadcrumb
          item={app.content}
          activeApp={app.active}
          appContent={app.content}
          site={site}
          site_info={site_info}
          layouts={layouts}
          menuItems={menuItems}
          displayGlobalTimeFilter={displayGlobalTimeFilter}
          onGlobalResolutionChange={(resolution) => setGlobalResolution(resolution)}
          refreshContent={() => { setRefreshTime(new Date()); setGlobalTime(); setGlobalResolution() }}
          minimizedPanels={minimizedPanels}
          unMinimizePanel={(panelInfo)=> unMinimizePanel(panelInfo)}
          metakeys={metakeys}
          togglePanels={togglePanels}
          visibilitySettings = {visibilitySettings}
          toggleMenu={ () => toggleCollapsed(!collapsed) }
          showMenu={collapsed}
          updateGlobalTime={updateGlobalTime}
          panelTimeValues={globalTime}
          globalResolution={globalResolution}
          resolutions = {resolutions}
          isNarrowScreen={isNarrowScreen}
          endpoints={endpoints}
          showCustomPanelButton={showCustomPanelButton}
        />
      
        {!isNarrowScreen &&
          <div style={{ position:"sticky", top: 60 }}>
            <div style={{ position:"absolute", top: 0 }}>
              <SiteNav
                key={app.active}
                topButton={true}
                collapsed={collapsed}
              />
            </div>
          </div>
        }
    
        <div className="mainContent">
          {!isNarrowScreen &&   
            <SiteNav
              key={app.active}
              appActive={app.active}
              activeSubMenu={app.subMenu}
              site={site}
              menuItems={menuItems}
              collapsed={collapsed}
              endpointsExists={endpoints.length > 0}
            />
          }
          <AndritzLogo />
          <div id="muuriContent" style={{flex:"auto"}}>
            {app.content && panels ? (
              <DraggableGrid
                key={app.active}
                ref={muuriRef}
                dragEnabled={draggable}
                dragHandle=".card-title"
                dragCssProps={{ touchAction: "auto" }}
                onDragEnd={(data, event) => { if(event.target.className === "card-title draggable" || event.target.className === "boa-card-header") { savePanelOrder() } }}
                data={panels}
                renderItem={ (item, i) => { return <div key={`gridContent_${i}`} id={item.id}>{item.elem}</div> } }
              />
            ) : (
              <GridSelection
                menuItems={menuItems}
                siteChangelog={siteChangelog}
                endpointsExists={endpoints.length > 0}
              />
            )}
          </div>
        </div>

        {showBottomBar() && <BottomBar site_info={site_info} extraFeatures={menuItems.bottomBar} />}
      </section>
    );
  } else { return <div></div>}
}