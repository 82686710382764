import React, { useState, useEffect, useCallback, useRef } from "react";
import { TagPicker, Button } from 'rsuite';
import { translate, useTranslateToString } from "../languages";

const TagList = (props) => {
    let { 
        defaultValue, 
        style, 
        onChange, 
        tags 
    } = props;

    const [sortField, setSortField]             = useState("id");
    const [sortOrder, setSortOrder]             = useState("asc");
    const [visibleFields, setVisibleFields]     = useState(["id", "tagname", "description"]);
    const [selectedTags, setSelectedTags]       = useState(defaultValue || []);
    const [selectedOnTop, toggleSelectedOnTop]  = useState(false);

    const placeholderText = useTranslateToString("Select tags");
    const tagPickerRef = useRef();

    const handleChange = useCallback((key, value) => {
        setSelectedTags(value);
        onChange(value);
    }, [onChange]);

    useEffect(() => {
        if (tags) { setSelectedTags(defaultValue); }
    }, [tags, defaultValue]);

    const handleSort = (field) => {
        if (sortField === field) {
          setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
        } else {
          setSortField(field);
          setSortOrder('asc');
        }
      };

    const toggleFieldVisibility = (field) => {
        setVisibleFields((prevFields) =>
            prevFields.includes(field)
            ? prevFields.filter((f) => f !== field)
            : [...prevFields, field]
        );
    };

    const getSortIcon = (field) => {
        if (sortField === field) {
          return sortOrder === 'asc' ? 'fas fa-sort-amount-down-alt' : 'fas fa-sort-amount-up-alt';
        }
        return 'fas fa-sort gray';
    };

    const customTagPickerMenu = (item) => {
        let descriptionLength = 12;
        let tagnameLength = 12;
        let idLength = 1;
        if (visibleFields.length === 3) {
          descriptionLength = 6;
          tagnameLength = 5; // id col is always 1
        } else if (visibleFields.includes('tagname') && visibleFields.includes('description')){ // id is hidden
          descriptionLength = 6;
          tagnameLength = 6;
        } else if (visibleFields.length === 1) { // only 1 field is visible
          idLength = 12;  
        } else { // id and either tagname or description is visible
          tagnameLength = 11; // id col is always 1
          descriptionLength = 11; // id col is always 1
        }
        return (
          <div className="row" style={{ display: "flex", alignItems: "center" }}>
            
            {visibleFields.includes('id') &&
              <div 
                className={`col-${idLength}`} 
                style={{ 
                  overflow: "hidden", 
                  textOverflow: "ellipsis", 
                  whiteSpace: "nowrap", 
                  fontSize: 14, 
                  flexShrink: 0 
                }}
              >
                <span>{item.id}</span> 
              </div>
            }
    
            {visibleFields.includes('tagname') &&
              <div 
                className={`col-${tagnameLength}`} 
                style={{ 
                  overflow: "hidden", 
                  textOverflow: "ellipsis", 
                  whiteSpace: "nowrap", 
                  fontSize: 14, 
                  flexShrink: 0 
                }}>
                  <span>{item.tagname}</span>
              </div>
            }
    
            {visibleFields.includes('description') && 
              <div 
                className={`col-${descriptionLength}`} 
                style={{ 
                  overflow: "hidden", 
                  textOverflow: "ellipsis", 
                  whiteSpace: "nowrap", 
                  fontSize: 14, 
                  flexGrow: 1 
                }}>
                <span>{item.description}</span>
              </div>
            }
    
          </div>
        );
      };
  
    const TagPickerExtraFooter = () => {
        const iconStyle = { fontSize: 16, marginRight: 10, cursor: "pointer" };
        return(
            <div className="tagPickerExtraFooter">
                
                <div 
                style={{ 
                    display: "flex", 
                    justifyContent: "space-between", 
                    alignItems: "center", 
                    padding: "0px 15px",
                    marginBottom: "15px" 
                }}
                >
                <div style={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
                    <i 
                    className={visibleFields.includes("id") ? "fas fa-eye" : "fas fa-eye-slash gray"} 
                    style={iconStyle} 
                    onClick={() => toggleFieldVisibility('id')} 
                    />
                    <i 
                    className={getSortIcon('id')} 
                    style={iconStyle} 
                    onClick={() => handleSort('id')} 
                    />
                    <span>{translate("ID")}</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', margin: 'auto' }}>
                    <i 
                    className={visibleFields.includes("tagname") ? "fas fa-eye" : "fas fa-eye-slash gray"} 
                    style={iconStyle} 
                    onClick={() => toggleFieldVisibility('tagname')} 
                    />
                    <i 
                    className={getSortIcon("name")} 
                    style={iconStyle} 
                    onClick={() => handleSort('name')} 
                    />
                    <span>{translate("Tagname")}</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}>
                    <i 
                    className={visibleFields.includes("description") ? "fas fa-eye" : "fas fa-eye-slash gray"} 
                    style={iconStyle} 
                    onClick={() => toggleFieldVisibility('description')}
                    />
                    <i 
                    className={getSortIcon("description")} 
                    style={iconStyle} 
                    onClick={() => handleSort('description')} 
                    />
                    <span>{translate("Description")}</span>
                </div>
                </div>

                <div 
                style={{ 
                    display: "flex", 
                    justifyContent: "space-between", 
                    alignItems: "center", 
                    padding: "0px 15px"
                }}
                >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <i 
                    className={selectedOnTop ? "fas fa-check-square" : "far fa-square" }
                    style={iconStyle} 
                    onClick={() => toggleSelectedOnTop(!selectedOnTop)} 
                    />
                    {translate("Selected on the top")}
                </div>
                <div style={{ display: 'flex', alignItems: 'center'}}>
                    <Button 
                        appearance="ghost" 
                        style={{ float: "right" }}
                        onClick={()=> tagPickerRef.current.close()}
                    >
                        {translate("OK")}
                    </Button>
                </div>
                </div>
            </div>
        );
    }

    const sortTags = (a, b) => {
        if (sortField === "name") { 
          if (sortOrder === "asc") { return a.tagname && b.tagname ? a.tagname.toLowerCase().localeCompare(b.tagname.toLowerCase()) : -1; } 
          else { return a.tagname && b.tagname ? b.tagname.toLowerCase().localeCompare(a.tagname.toLowerCase()) : -1; }
        } else if (sortField === "description") { 
          if (sortOrder === "asc") { return a.description && b.description ? a.description.toLowerCase().localeCompare(b.description.toLowerCase()) : -1; }
          else { return a.description && b.description ? b.description.toLowerCase().localeCompare(a.description.toLowerCase()) : -1; }
        } else { 
          if (sortOrder === "asc") { return a.id > b.id ? 1 : -1; } 
          else { return a.id > b.id ? -1 : 1; }
        }
    }

    if (!tags) { return null; }

    // Combine tag properties so they can be used as value key for the TagPicker
    const combinedTags = tags.map(tag => {
        const selectedTag = selectedTags.find(selectedTag => selectedTag[2] === tag.id);
        return {
            ...tag,
            combinedProperties: selectedTag ? selectedTag : [tag.tagname, tag.description, tag.id, tag.unit]
        };
    });

    const selectedTagIds = selectedTags.map(tag => tag[2]);
    const sortedTags = combinedTags.sort((a, b) => sortTags(a, b));
    const finalTags = selectedOnTop
        ? [
            ...sortedTags.filter(tag => selectedTagIds.includes(tag.id)),
            ...sortedTags.filter(tag => !selectedTagIds.includes(tag.id))
        ]
        : sortedTags;

    return (
        <TagPicker
            virtualized={true}
            ref={tagPickerRef}
            data={finalTags}
            placeholder={placeholderText}
            searchable={true}
            style={style}
            onChange={(value) => handleChange("tags", value)}
            renderExtraFooter={() => <TagPickerExtraFooter />}
            renderMenuItem={(label, item) => customTagPickerMenu(item)}
            valueKey="combinedProperties"
            labelKey="id"
            value={selectedTags}
            searchBy={(keyword, label, item) => {
                if (Object.values(item).filter(removeNulls => removeNulls).some(value => value.toString().toLowerCase().includes(keyword.toLowerCase()))) {
                    return item;
                }
            }}
        />
    );
};

export default TagList;