import React, { useRef } from 'react';
import { translate, useTranslateToString } from '../languages';
import { SelectPicker, Divider } from 'rsuite';

const timeWindow = [
  { label: "10min", value: 600 },
  { label: "30min", value: 1800 },
  { label: "1h", value: 3600 },
  { label: "8h", value: 28800 },
  { label: "1d", value: 86400 },
  { label: "7d", value: 604800 }
];

const BarChartOptions = (props) => {
  const { defaultValues, onChange } = props;
  const isInitialized = useRef(false);

  // Initialize default values only once
  if (!isInitialized.current) {
    if (defaultValues?.timeWindow === undefined) {
      onChange({ ...defaultValues, timeWindow: 86400 });
    }
    isInitialized.current = true;
  }

  const handleChange = (key, value) => {
    onChange({ ...defaultValues, [key]: value });
  };

  return (
    <>
      <Divider>{translate("Bar chart options")}</Divider>
      <div className="flex-container">
        <SelectPicker
          data={timeWindow}
          searchable={false}
          label={useTranslateToString("Time window")}
          value={defaultValues?.timeWindow || 86400}
          onChange={(value) => handleChange("timeWindow", value)}
        />
      </div>
      <Divider />
    </>
  );
};

export default BarChartOptions;
