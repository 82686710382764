// Sets the values of the custom panel based on the options selected by the user. 
// It returns the updated custom panel object and is used at selectedGrid.js.

// Resolution buttons come from sitenav and are used to set the resolution buttons for the panel
const setPanelValues = (customTemplates, options, resolutionButtons, panelType, resolutions) => {
    const sharedOptions = options.properties.sharedOptions;
    const gaugeOptions = options.properties.gaugeOptions;
    const lineOptions = options.properties.lineOptions;
    const barOptions = options.properties.barOptions; 
    let layout = Object.keys(customTemplates).find(key => key === sharedOptions.type);
    let customTemplate = customTemplates[layout];

    if (!layout) { return false }

    setSharedOptions(customTemplate, sharedOptions, options.id);
    if (panelType === "Pie") {setPieOptions(customTemplate, sharedOptions)};
    if (panelType === "Line") { setLineOptions(customTemplate, lineOptions, sharedOptions); }
    if (panelType === "Gauge") { setGaugeOptions(customTemplate, gaugeOptions, sharedOptions); }
    if (panelType === "Bar") { setBarOptions(customTemplate, barOptions, sharedOptions); }
    customTemplate.creator = options?.username?.split("@")[0] || "Unknown";
    
    if (resolutionButtons) { customTemplate.resolutions = resolutionButtons; }

    if (options.isDemo) { customTemplate.isDemo = true; }

    // If resolutions have been defined in the tag search, add resolutions to the custom panels as well
    if (resolutions) {
      customTemplate.resolutions = resolutions;
    }
    return customTemplate;
}
const setPieOptions = (customTemplate, sharedOptions) => {
    // Tooltip supports only 1 unit for now
    const tagUnit = sharedOptions.tags[0][3] || "" // Example: m3/h
    customTemplate.rows.chart_0.tooltip.pointFormat = `<b>{point.y:.1f} ${tagUnit}</b> ({point.percentage:.1f}%) <br> Total: {point.total:.1f} ${tagUnit}`;
}

const setSharedOptions = (customTemplate, sharedOptions, id) => {
    let tagNames = sharedOptions.tags.map(tag => tag[0]); // Example: 345-pw-315
    let tagDescriptions = sharedOptions.tags?.map(tag => tag?.[1] ?? "") ?? ""; // Example: Air Flow
    let tagIds = sharedOptions.tags.map(tag => tag?.[2]); // Example: 1000
    let tagColors = sharedOptions.tags.map(tag => tag[4]); // Example: #FF0000
    let props = [];

    // Create a series object for each tag
    for (let index = 0; index < tagNames.length; index++) {
        let obj = {
            id: tagNames[index],
            name: tagDescriptions[index] === "" ? tagNames[index] : tagDescriptions[index],
            color: tagColors[index],
        };
        obj = {...obj, ...customTemplate.sources.series[0].seriesprops[0]};
        props.push(obj);
    }

    customTemplate.sources.series[0].queryparams.tags = sharedOptions.filter_indexing === "ID" ? tagIds : tagNames;
    customTemplate.id = id;
    customTemplate.title = sharedOptions?.name; // Globe icon is added in item.js if panel is global
    customTemplate.sources.series[0].seriesprops = props;
    customTemplate.info.id = id;
    customTemplate.isPublic = sharedOptions?.isPublic ?? false;
    customTemplate.info.name = sharedOptions?.name;
    customTemplate.sources.series[0].url = sharedOptions.endpoint;

    return customTemplate;
}

const setGaugeOptions = (customTemplate, gaugeOptions, sharedOptions) => {
    // Color ranges | Example: { color: "Green", min: 0, max: 50 }
    const yAxis = customTemplate?.rows?.chart_0?.yAxis ?? null;

    if (gaugeOptions?.colorRanges) {
        const colorRangesMap = new Map();
        gaugeOptions.colorRanges.forEach((range) => {
            const color = range.color?.toLowerCase() ?? range.color ?? "#55BF3B";
            const existingRange = colorRangesMap.get(color);
            
            if (existingRange) {
                existingRange[0] = Math.min(existingRange[0], Number(range.min));
                existingRange[1] = Math.max(existingRange[1], Number(range.max));
            } else {
                colorRangesMap.set(color, [Number(range.min), Number(range.max)]);
            }
        });
        
        let plotBands = [];
        
        colorRangesMap.forEach((range, color) => {
            plotBands.push({
                from: range[0],
                to: range[1],
                color: color,
                thickness: 20,
            });
        });
        
        if (yAxis) {
          // Calculate the smallest and largest values across both min and max
          const allValues = gaugeOptions.colorRanges.flatMap(r => [r.min, r.max]);
          const minRange = Math.min(...allValues); // Smallest value among all min and max
          const maxRange = Math.max(...allValues); // Largest value among all min and max

          const isDescending = gaugeOptions.colorRanges[0].min > gaugeOptions.colorRanges[0].max;
          if (isDescending) yAxis.reversed = true;
      
          // Set the yAxis min and max based on the calculated ranges
          yAxis.min = minRange;
          yAxis.max = maxRange;
      
          const range = maxRange - minRange;
      
          // Adjust tick frequency based on highest and lowest values
          if (range <= 50) {
              yAxis.minorTickInterval = (range / 20);
              yAxis.tickInterval = (range / 10);
          } else if (range <= 300) {
              yAxis.minorTickInterval = Math.ceil(range / (range / 5));
              yAxis.tickInterval = Math.ceil(range / (range / 10));
          } else {
              yAxis.minorTickInterval = Math.ceil(range / (range / 20));
              yAxis.tickInterval = Math.ceil(range / (range / 40));
          }
      }
        
        // Update the customTemplate with new yAxis
        if (yAxis != null && colorRangesMap.size > 0) {
            let updatedYAxis = {
                ...yAxis,
                plotBands: plotBands,
            };
            customTemplate.rows.chart_0.yAxis = updatedYAxis;
        }
    }

    // Change the number of decimals displayed
    if (gaugeOptions?.decimalAmount) {
        customTemplate.sources.series[0].seriesprops[0].dataLabels.format = `{y:.${gaugeOptions.decimalAmount};f}`
    }

    // Change unit
    if (sharedOptions?.tags[0][3] && customTemplate.sources.series[0].seriesprops[0].dataLabels?.format) {
        customTemplate.sources.series[0].seriesprops[0].dataLabels.format += ` ${sharedOptions.tags[0][3]}`;
    }
}

const setLineOptions = (customTemplate, lineOptions, sharedOptions) => {
    const descriptions = sharedOptions.tags.map(tag => tag[1] ?? "");
    const tagNames = sharedOptions.tags.map(tag => tag[0] ?? "");
    const tagColors = sharedOptions.tags.map(tag => tag[4] ?? "");
    const units = sharedOptions.tags.map(tag => tag[3] ?? "");
    const yAxisHeights = sharedOptions.tags.map(tag => tag[5] ?? "");
    const lineChart = customTemplate.rows?.chart_0 ?? null;
    let yAxisArray = [];
    let tooltip;
    
    // Update the customTemplate with new yAxis
    if (lineChart != null) {
        // Create yAxis for each unique unit
        const unitGroups = units.reduce((acc, unit, index) => {
            const min = yAxisHeights[index]?.min ?? null;
            const max = yAxisHeights[index]?.max ?? null;
        
            // Create a unique key based on unit, min, and max values
            const key = `${unit}_min:${min}_max:${max}`;
        
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(index);
            return acc;
        }, {});
        
        yAxisArray = Object.keys(unitGroups).map((key, groupIndex) => {
            const indices = unitGroups[key];
            const colors = indices.map(index => tagColors[index]);
            const uniqueColors = [...new Set(colors)];
            const color = uniqueColors.length === 1 ? uniqueColors[0] : null;
        
            // Extract min and max from the key
            const [unit, minValue, maxValue] = key.split(/_min:|_max:/);
            const minHeight = minValue !== "null" ? parseFloat(minValue) : null;
            const maxHeight = maxValue !== "null" ? parseFloat(maxValue) : null;
        
            return {
                title: {
                    text: unit,
                    style: {
                        color: color
                    }
                },
                labels: {
                    style: {
                        color: color
                    }
                },
                min: minHeight,
                max: maxHeight,
                opposite: groupIndex % 2 === 1, // Alternate the side for each yAxis
            };
        });
        
        // Assign each series to the corresponding yAxis
        customTemplate.sources.series[0].seriesprops.forEach((series, index) => {
            // Ensure the yAxis index is within the bounds of yAxisArray
            series.yAxis = Math.min(index, yAxisArray.length - 1);
        });
        
        // Update selected time window button
        let timeButtons = customTemplate?.timeButtons // Example: [600, 1800, 3600, 28800, 86400, 604800]

        // Convert selected time window to string, so it becomes selected
        if (timeButtons) {
            timeButtons = timeButtons.map(button => {
                return button === lineOptions.timeWindow ? button.toString() : button;
            });
            customTemplate.timeButtons = timeButtons;
            customTemplate.sources.series[0].queryparams.window = lineOptions.timeWindow;
        }

        // Tooltip configuration
        tooltip = {
            "shared": true,
            "useHTML": true,
            "formatter": function () {
                let tooltipHTML = `<table><tr><th colspan='2'>${Highcharts.dateFormat('%d.%m.%Y, %H:%M:%S', this.x)}</th></tr>`;
                this.points.forEach(point => {
                    const tagIndex = point.series.index;
                    const unit = units[tagIndex] || "";
                    tooltipHTML += `<tr><td style='color: ${point.series.color}'>${point.series.name} </td><td><b>${Highcharts.numberFormat(point.y, 2)} ${unit} </b></td></tr>`;
                });
                tooltipHTML += "</table>";
                return tooltipHTML;
            },
            "xDateFormat": "%d.%m.%Y, %H:%M:%S",
            "footerFormat": "</table>",
            "headerFormat": ""
        };

        // Add statistics table
        customTemplate.rows.table = createTableConfiguration(descriptions, tagNames, units);
    }

    customTemplate.rows.chart_0 = {
        ...lineChart,
        yAxis: yAxisArray,
        tooltip: tooltip
    };
}

const setBarOptions = (customTemplate, barOptions, sharedOptions) => {
    const barChart = customTemplate.rows?.chart_0 ?? null;
    const descriptions = sharedOptions.tags.map(tag => tag[1] ?? "");
    const tagNames = sharedOptions.tags.map(tag => tag[0] ?? "");
    const tagColors = sharedOptions.tags.map(tag => tag[4] ?? "");
    const units = sharedOptions.tags.map(tag => tag[3] ?? "");
    const yAxisHeights = sharedOptions.tags.map(tag => tag[5] ?? "");
    let yAxisArray = [];

    if (barChart != null) {
        yAxisArray = units.map((unit, index) => ({
            title: {
                text: unit,
                style: {
                    color: tagColors[index]
                }
            },
            labels: {
                style: {
                    color: tagColors[index]
                }
            },
            min: yAxisHeights[index]?.min ?? null,
            max: yAxisHeights[index]?.max ?? null,
            opposite: index % 2 === 1, // Alternate the side for each yAxis
        }));

        const updatedBarChart = {
            ...barChart,

            yAxis: yAxisArray,
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true
                    },
                    stacking: null,
                    colorByPoint: true,  
                    colors: tagColors    // Apply tag colors to bars
                }
            }
        };

        // Update selected time window button
        let timeButtons = customTemplate?.timeButtons // Example: [600, 1800, 3600, 28800, 86400, 604800]

        // Convert selected time window to string, so it becomes selected
        if (timeButtons) {
            timeButtons = timeButtons.map(button => {
                return button === barOptions.timeWindow ? button.toString() : button;
            });
            customTemplate.timeButtons = timeButtons;
            customTemplate.sources.series[0].queryparams.window = barOptions.timeWindow;
        }

        // Add statistics table
        customTemplate.rows.table = createTableConfiguration(descriptions, tagNames, units);

        customTemplate.rows.chart_0 = updatedBarChart;
    }
};

function createTableConfiguration(descriptions, tagNames, units) {
    let table = {
        metakeys: ["Tables"],
        thead: [
            "Position",
            "Tag",
            "Unit",
            "Average",
            "Min",
            "Max",
            "Max-min",
            "Last value"
        ],
        tbody: {}
    };

    for (let i = 0; i < descriptions.length; i++) {
        table.tbody[i] = {
            cells: [
                { title: descriptions[i] },             // Description
                { title: tagNames[i] },                 // Tag name
                { title: units[i] },                    // Unit
                { decimals: 2, choose: "avg", tag: tagNames[i] },    // Avg
                { decimals: 2, choose: "min", tag: tagNames[i] },    // Min
                { decimals: 2, choose: "max", tag: tagNames[i] },    // Max
                {
                    tags: {
                        x: { tag: tagNames[i], choose: "min" },
                        y: { tag: tagNames[i], choose: "max" }
                    },
                    decimals: 2,
                    calculate: "y-x"
                },  // Max - min
                {
                    decimals: 2,
                    highlight: [
                        {
                            condition: { over: -100000 },
                            highlightCss: { color: "#f79646" }
                        }
                    ],
                    choose: "last",
                    tag: tagNames[i]
                }   // Last value
            ]
        };
    }
    return table
}

export default setPanelValues;
