import React from "react";

import { Modal, Button } from "rsuite";
import { isFunction } from "lodash";

import { changeLog } from ".";
import { generateGuid } from "../utils";
import { translate } from "../languages";

function ChangeLogModal(props) {
  const {
    onClose
  } = props;

  const latestViewedChangelogVersion = localStorage.getItem("latestViewedChangelogVersion");
  const latestChangelogVersion = changeLog?.[0]?.version;

  /**
   * Handles closing of the changelog modal. Calls onClose
   * function (received from parent) if applicable.
   */
  function handleClose() {
    localStorage.setItem("latestViewedChangelogVersion", latestChangelogVersion);
    if (isFunction(onClose)) {
      onClose();
    }
  }

  /**
   * Constructs and returns a header string for a single
   * changelog entry in the changelog display.
   * @param {Object} entry a single Object entry in changelog
   * @returns {string} header string for changelog display
   */
  function getHeader(entry) {
    let header = entry.version;
    if (entry.date) {
      header += ` --- ${entry.date}`;
    }
    return header;
  }

  /**
   * Constructs changelog content by looping through
   * changelog Array.
   * @returns {JSX.Element} content of changelog
   */
  function parseContent() {
    let hitLatestViewedChangelogVersion = false;
    return (
      <div data-testid="changelog-main-div">
        {
          changeLog.map(entry => { // map each version number

            // give entries a different class until
            // first already seen entry comes across
            if (entry.version === latestViewedChangelogVersion) {
              hitLatestViewedChangelogVersion = true;
            }
            return (
              <React.Fragment key={`changelog-${generateGuid()}`}>
                <div
                  className={hitLatestViewedChangelogVersion ? "changelog-entry changelog-read" : "changelog-entry changelog-unread"}
                  key={`changelog-${generateGuid()}`}
                >
                  <h4>
                    {getHeader(entry)}
                  </h4>
                  <ul>
                    {
                      entry.description.map((description) => { // map each description
                        return (
                          <li key={`changelog-${generateGuid()}`}>
                            {description}
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
                <hr key={`changelog-${generateGuid()}`} />
              </React.Fragment>
            );
          })
        }
      </div>
    );
  }

  return( 
    <React.Fragment>
      <Modal.Header>
        <h3>
          {translate("Changelog")}
        </h3>
      </Modal.Header>

      <Modal.Body>
        <div>
          {parseContent()}
        </div>
      </Modal.Body>

      <Modal.Footer>

      <Button
          data-testid="changelog-close-button"
          onClick={handleClose}
          appearance="primary" 
          color="red"
          style={{marginTop: "10px"}}
          > {translate("Close")}
        </Button>
      </Modal.Footer>
    </React.Fragment>
  );
}

export default ChangeLogModal;
